import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import ViewProfile from '../../Profile';
import HeaderDetailJob from '../../Header';
import { columns } from './columns';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SoundOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Loading, SearchDetailJob, FilterSiderDetailJob } from '../../../../../../components';
import { detailCandidate, unmountDetailCandidate } from '../../../../../../redux/actions/candidate/candidateAction';
import { Row, Col, Breadcrumb, PageHeader, Card, Typography, Menu, Modal, message, Table, Pagination, Space, Button } from 'antd';
import { listJobsApplied, detailJobActiveApplicant, inactiveJob, unmountListJobsApplied, unmountDetailJobsActiveApplicant, nextPhase, rejectApplicant } from '../../../../../../redux/actions/jobs/jobsAction';
const { confirm } = Modal;
const { Text } = Typography;

class DetailAppliedAgencyJobs extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      url: 'job_agency',
      checked: true,
      visible: false,
      selectedRowKeys: [],
      meta: {
        page: 1,
        perpage: 10,
        id: '',
        sort: '',
        field: '',
        search: '',
        minAge: '',
        maxAge: '',
        gender: ''
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta, url } = this.state;
    const { actionListApplied, actionGetDetail, match: { params } } = this.props;
    meta.id = Number(params.id)
    actionListApplied(meta, url)
    actionGetDetail(params.id, url)
  }

  handleChangeStatus = () => {
    const { url } = this.state;
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    confirm({
      title: 'Are you sure to set it as inactive?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, url)
        })
      },
    });
  }

  handleMenuPhase = (item) => {
    const { getData: { data } } = this.props;
    switch (item.title) {
      case "Applied":
        return this.props.history.push(`/agency/jobs/detail/${data.id}/active-applicants/${item.phaseCode}`);
      case "Qualified":
        return this.props.history.push(`/agency/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}`);
      case "Interview":
        return this.props.history.push(`/agency/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}/interview`);
      case "Offered":
        return this.props.history.push(`/agency/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}`);
      case "Hired":
        return this.props.history.push(`/agency/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}`);
      default:
        break;
    }
  }

  modalNextStage = (id) => {
    const self = this;
    const { meta, url, selectedRowKeys } = this.state;
    const { actionNextPhase, actionListApplied } = this.props;
    const idApplicant = selectedRowKeys.length > 0 ? selectedRowKeys.join() : id
    confirm({
      title: 'The application will be processed to the next stage.',
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        return actionNextPhase(idApplicant, url, () => {
          message.success('Successfully moved')
          actionListApplied(meta, url)
          self.setState({ selectedRowKeys: [] })
        }, (err) => message.error(err))
      },
    });
  }

  modalRejected = (id) => {
    const self = this;
    const { meta, url, selectedRowKeys } = this.state;
    const { actionReject, actionListApplied } = this.props;
    const idApplicant = selectedRowKeys.length > 0 ? selectedRowKeys.join() : id
    confirm({
      title: 'The application will be moved to rejected. ',
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        return actionReject(idApplicant, url, () => {
          message.success('Successfully moved')
          actionListApplied(meta, url)
          self.setState({ selectedRowKeys: [] })
        }, (err) => message.error(err))
      },
    });
  }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionListApplied } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionListApplied(meta, url)
  }

  onFilter = value => {
    const { meta, url } = this.state;
    const { actionListApplied } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.gender = value.gender
    meta.maxAge = value.maxAge
    meta.minAge = value.minAge
    return actionListApplied(meta, url)
  }

  pagination = (page, perpage) => {
    const { meta, url } = this.state;
    const { actionListApplied } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionListApplied(meta, url)
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onPriviewProfile = (id) => {
    const { actionGetProfile } = this.props;
    return actionGetProfile(id, () => {
      this.setState({ visible: true })
    })
  }

  onCancel = () => {
    this.setState({ visible: false })
  }

  render() {
    const { selectedRowKeys, checked, visible } = this.state;
    const { getData: { data, loading }, getApplied } = this.props;

    if(loading){
      return <Loading />
    }

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <React.Fragment>
        <Row gutter={[16, 8]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SoundOutlined /> Job Posting</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/agency/jobs">Job Posting List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Text strong>
                  {data.jobNumber}  
                </Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Job Posting Detail' />
          </Col>
          {/* Card Header */}
          <Col span={24}>
            <HeaderDetailJob 
              data={data} 
              checked={checked} 
              handleChangeStatus={(e) => this.handleChangeStatus(e)} 
            />
          </Col>
          {/* Menu */}
          <Col span={24}>
            <Menu defaultSelectedKeys={['2']} mode="horizontal" style={{ background: 'none'  }}>
              <Menu.Item key="1">
                <Link to={`/agency/jobs/detail/${data.id}/information`}>Information</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={`/agency/jobs/detail/${data.id}/active-applicants/PT00`}>Active Applicants</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to={`/agency/jobs/detail/${data.id}/rejected-applicants`}>Rejected Applicants</Link>
              </Menu.Item>
            </Menu>
          </Col>
          {/* Content */}
          <Col span={24}>
            <Card title="Active Applicants">
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Menu defaultSelectedKeys={['0']} mode="horizontal" style={{ background: 'none'  }}>
                    <Menu.Item key={'0'}>
                      <Link to={`/agency/jobs/detail/${data.id}/active-applicants/PT00`}>Applied</Link>
                    </Menu.Item>
                    {
                      data.listPhase.map((item, i) => (
                        <Menu.Item key={item.sortOrder} onClick={() => this.handleMenuPhase(item)}>
                          <Link key={i} to="#">
                            {item.title} 
                          </Link>
                        </Menu.Item>
                      ))
                    }
                  </Menu>
                </Col>
                <Col span={24}>
                  <SearchDetailJob onSearch={this.onSearch} />
                </Col>
                {
                  hasSelected ?
                  <React.Fragment>
                    <Col span={24}>
                      <Space direction="vertical">
                        <Text>Selected applicants count: <Text strong>{selectedRowKeys.length}</Text> </Text>
                        <Space>
                          <Text>Move To:</Text>
                          <Button onClick={this.modalRejected}>Rejected</Button>
                          <Button type="primary" onClick={this.modalNextStage}>Qualified</Button>
                        </Space>
                      </Space>
                    </Col>
                  </React.Fragment>
                  :
                  null
                }
                {/* Filter */}
                <Col span={6}>
                  <FilterSiderDetailJob onFilter={this.onFilter} />
                </Col>
                {/* Table */}
                <Col span={18}>
                  <Table 
                    size="small"
                    style={{ marginBottom: 20 }}
                    rowKey={(i) => i.id} 
                    dataSource={getApplied.data} 
                    loading={getApplied.loading} 
                    columns={columns(this.onPriviewProfile, this.modalNextStage, this.modalRejected)} 
                    pagination={false}
                    scroll={{ x: 900 }}
                    rowSelection={rowSelection}
                  />
                  {/* Pagination */}
                  <Pagination
                    total={getApplied.pagination.total}
                    onChange={this.pagination}
                    current={getApplied.pagination.page}
                    pageSize={getApplied.pagination.perpage}
                    showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal 
          centered
          width={1000}
          visible={visible}
          title="Applicant Profile"
          onCancel={this.onCancel}
          footer={
            <Button key="back" type="primary" onClick={this.onCancel}>Okay</Button>
          }
        >
          <div style={{ height: 600, overflowY: 'auto', overflowX: 'hidden' }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountListApplied, unmountGetProfile } = this.props;
    unmountListApplied()
    unmountGetDetail()
    unmountGetProfile()
  }
}

const mapStateToProps = (state) => ({
  getData       : state.jobs.activeApplicant,
  getApplied    : state.jobs.applied,
  getProfile    : state.candidate.detail
})

const mapDispatchToProps = {
  actionInactive        : inactiveJob,
  actionNextPhase       : nextPhase,
  actionReject          : rejectApplicant,
  actionGetDetail       : detailJobActiveApplicant,
  actionGetProfile      : detailCandidate,
  actionListApplied     : listJobsApplied,
  unmountListApplied    : unmountListJobsApplied,
  unmountGetProfile     : unmountDetailCandidate,
  unmountGetDetail      : unmountDetailJobsActiveApplicant
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailAppliedAgencyJobs)
