import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { DeploymentUnitOutlined } from '@ant-design/icons';
import { PageHeader, Card, Row, Col, Descriptions, Typography, Image, Breadcrumb } from 'antd';
import { detailPartner, unmountDetailPartner } from '../../../../redux/actions/partners/partnersAction';
const { Text } = Typography;

class DetailPartner extends Component {

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  render() {
    const { getData: { data, loading } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><DeploymentUnitOutlined /> Partners</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/partners">Partners List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data.name ? data.name : '-' }</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Partner Detail' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Partner Name</Text>}>
                      {data.name ? data.name : 'N/A' }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Partner Email</Text>}>
                      {data.email ? data.email : 'N/A' }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person</Text>}>
                      {data.picName ? data.picName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person Mobile Number</Text>}>
                      {data.mobilePhone ? `+${data.callingCode}${data.mobilePhone}` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Partner Type</Text>}>
                      {data.partnersTypeName ? data.partnersTypeName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person Email</Text>}>
                      {data.picEmail ? data.picEmail : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Office Phone Number</Text>}>
                      {data.officePhone ? data.officePhone : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Country</Text>}>
                      {data.countryName ? data.countryName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Province</Text>}>
                      {data.provinceName ? data.provinceName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">City</Text>}>
                      {data.cityName ? data.cityName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Street Address</Text>}>
                      {data.address ? data.address : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Postal Code</Text>}>
                      {data.zipCode ? data.zipCode : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Website</Text>}>
                      {data.website ? data.website : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Logo</Text>}>
                      {data.logoUrl ? <Image width={100} src={data.logoUrl} /> : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">About</Text>}>
                      {data.about ? data.about : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    return unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData: state.partners.detail
})

const mapDispatchToProps = {
  actionGetDetail   : detailPartner,
  unmountGetDetail  : unmountDetailPartner
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailPartner)
