import React, { Component } from 'react';
import moment from 'moment';
import EditInterview from './Edit';
import debounce from 'lodash/debounce';
import ViewProfile from '../../Profile';
import ContentInterview from './Content';
import HeaderDetailJob from '../../Header';
import ListApplicant from '../../ListApplicant';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../../components';
import { SoundOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { detailCandidate, unmountDetailCandidate } from '../../../../../../redux/actions/candidate/candidateAction';
import { Row, Col, Breadcrumb, PageHeader, Card, Typography, Menu, Modal, message, Empty, Input, Button, AutoComplete } from 'antd';
import { addOffer, cancelApplicant, detailApplicant, detailJobActiveApplicant, detailJobInterview, editInterview, inactiveJob, listApplicant, unmountDetailJobsActiveApplicant, unmountDetailJobsInterview, unmountListApplicant } from '../../../../../../redux/actions/jobs/jobsAction';
const { Text } = Typography;
const { confirm } = Modal;

class DetailInterviewEmployerJobs extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      url: 'job_employers',
      isMethod: true,
      listData: [],
      checked: true,
      visible: false,
      visibleProfile: false,
      valueForm: null,
      idApplicant: null,
      idInterview: null,
      dataApplicant: null,
      isResult: true,
      indexApplicant: null,
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        id: null,
        phaseId: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta, url } = this.state;
    const { actionGetApplicant, actionGetDetail, actionGetProfile, actionDetailApplicant, match: { params } } = this.props;
    meta.id = params.id
    meta.phaseId = params.phaseId
    actionGetApplicant(meta, url, (response) => {
      this.setState({ listData: response.data })
    })
    actionGetDetail(params.id, url)
    if(params.appliedId !== "interview"){
      return actionDetailApplicant(params.appliedId, meta.phaseId, url, (response) => {
        actionGetProfile(response.data.candidateId)
        this.setState({ idApplicant: response.data.id, dataApplicant: response.data })
      })
    }
  }

  handleChangeAssessmentResult = (e) => {
    this.setState({ isResult: e.target.value })
  }

  handleInfiniteOnLoad = () => {
    ('scroll');
  };

  handleChangeMethod = (e) => {
    this.formRef.current.setFieldsValue({
      location: null,
      virtualLink: null,
    });
    this.setState({ isMethod: e.target.value })
  }

  handleChangeValue = (value) => {
    this.setState({ valueForm: value })
  }

  handleChangeStatus = () => {
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    let self = this
    confirm({
      title: 'Are you sure to set it as inactive?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, self.state.url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, self.state.url)
        })
      },
    });
  }

  handleMenuPhase = (item) => {
    const { getData: { data } } = this.props;
    switch (item.title) {
      case "Applied":
        return this.props.history.push(`/employer/jobs/detail/${data.id}/active-applicants/${item.phaseCode}`);
      case "Qualified":
        return this.props.history.push(`/employer/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}`);
      case "Interview":
        return this.props.history.push(`/employer/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}/interview`);
      case "Offered":
        return this.props.history.push(`/employer/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}`);
      case "Hired":
        return this.props.history.push(`/employer/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}`);
      default:
        break;
    }
  }

  selectedApplicant = (value) => {
    const { isResult, dataApplicant } = this.state;
    const { actionGetDetailInterview, actionGetProfile, match: { params }, history } = this.props;
    history.push(`/employer/jobs/detail/${params.id}/active-applicants/PT02/${params.phaseId}/${value.id}`)
    this.setState({ idApplicant: value.id, dataApplicant: value })
    if(dataApplicant){
      this.formRef.current.setFieldsValue({
        isResult: isResult,
        notes: null,
      });
    }
    actionGetProfile(value.candidateId)
    actionGetDetailInterview(value.jobInterviewId, (response) => {
      this.setState({ isMethod: response.data.isMethod })
    })
  }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionGetApplicant } = this.props;
    meta.search = value === '' ? null : value
    return actionGetApplicant(meta, url, (response) => {
      this.setState({ listData: response.data })
    })
  }

  openModal = () => {
    const { dataApplicant } = this.state;
    const { actionGetDetailInterview } = this.props;
    actionGetDetailInterview(dataApplicant.jobInterviewId, (response) => {
      this.setState({ isMethod: response.data.isMethod, visible: true })
    })
  }

  openModalProfile = () => {
    this.setState({ visibleProfile: true })
  }

  onCancel = () => {
    this.setState({ visible: false })
  }

  onCancelProfile = () => {
    this.setState({ visibleProfile: false })
  }

  onFinish = (values) => {
    const self = this;
    const { dataApplicant, meta, url } = this.state;
    const { actionAddOffer, actionGetApplicant, match: { params } } = this.props;
    values.phaseId = Number(params.phaseId)
    values.jobAppliedId = dataApplicant.id
    if(values.isResult){
      confirm({
        title: 'The application will be processed to the next stage.',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          return actionAddOffer(values, () => {
            message.success('Successfully moved')
            actionGetApplicant(meta, url, (response) => {
              self.setState({ listData: response.data, dataApplicant: null })
            })
          })
        },
      });
    }else {
      confirm({
        title: 'This application will be moved to rejected.',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          return actionAddOffer(values, () => {
            message.success('Successfully moved')
            actionGetApplicant(meta, url, (response) => {
              self.setState({ listData: response.data, dataApplicant: null })
            })
          })
        },
      });
    }
  }

  onSubmit = () => {
    const { meta, url } = this.state;
    const { getInterview: { data }, match: { params }, actionUpdateInterview, actionGetApplicant, actionDetailApplicant } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.jobAppliedId = data.jobAppliedId
      values.id = data.id
      values.phaseId = Number(params.phaseId)
      values.interviewDate = moment(values.interviewDate).format('DD/MM/YYYY')
      values.interviewTime = moment(values.interviewTime).format('HH:mm:ss')
      values.notes = data.notes ? data.notes : null
      return actionUpdateInterview(values, () => {
        message.success('Data updated successfully')
        this.setState({ visible: false }, () => {
          actionDetailApplicant(params.appliedId, params.phaseId, url, (response) => {
            this.selectedApplicant(response.data)
          })
          return actionGetApplicant(meta, url, (response) => {
            this.setState({ listData: response.data, idApplicant: data.jobAppliedId })
          })
        })
      })
    })
  }

  onCancelInterview = (id) => {
    const { actionCancelInterview, actionGetApplicant } = this.props;
    const self = this;
    confirm({
      title: 'By cancelling this scheduled interview, the application will be moved to be rejected.',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        return actionCancelInterview(id, self.state.url, () => {
          message.success('Successfully moved')
          return actionGetApplicant(self.state.meta, self.state.url, (response) => {
            self.setState({ listData: response.data, dataApplicant: null })
          })
        })
      },
    });
  }

  render() {
    const { checked, idApplicant, dataApplicant, isResult, isMethod, listData, visible, visibleProfile, valueForm } = this.state;
    const { getData: { data, loading }, getApplicant } = this.props;
    if(loading){
      return <Loading />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SoundOutlined /> Job Posting</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/employer/jobs">Job Posting List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Text strong>
                  {data.jobNumber}  
                </Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Job Posting Detail' />
          </Col>
          {/* Card Header */}
          <Col span={24}>
            <HeaderDetailJob 
              data={data} 
              checked={checked} 
              handleChangeStatus={(e) => this.handleChangeStatus(e)} 
            />
          </Col>
          {/* Menu */}
          <Col span={24}>
            <Menu defaultSelectedKeys={['2']} mode="horizontal" style={{ background: 'none'  }}>
              <Menu.Item key="1">
                <Link to={`/employer/jobs/detail/${data.id}/information`}>Information</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={`/employer/jobs/detail/${data.id}/active-applicants/PT00`}>Active Applicants</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to={`/employer/jobs/detail/${data.id}/rejected-applicants`}>Rejected Applicants</Link>
              </Menu.Item>
            </Menu>
          </Col>
          {/* Content */}
          <Col span={24}>
            <Card title="Active Applicants">
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Menu defaultSelectedKeys={['2']} mode="horizontal" style={{ background: 'none'  }}>
                    <Menu.Item key={0}>
                      <Link to={`/employer/jobs/detail/${data.id}/active-applicants/PT00`}>Applied</Link>
                    </Menu.Item>
                    {
                      data.listPhase.map((item, i) => (
                        <Menu.Item key={item.sortOrder} onClick={() => this.handleMenuPhase(item)}>
                          <Link key={i} to="#">
                            {item.title} 
                          </Link>
                        </Menu.Item>
                      ))
                    }
                  </Menu>
                </Col>
                <Col span={24}>
                  <AutoComplete
                    onSearch={this.onSearch} 
                    style={{ width: '100%' }} 
                    allowClear
                  >
                    <Input 
                      prefix={<SearchOutlined />} 
                      style={{ width: '28%'}} 
                      placeholder="Search by Applicant Name" 
                    />
                  </AutoComplete>
                </Col>
                <Col span={24}>
                  <Text>Showing {getApplicant.pagination.total} Data</Text>
                </Col>
                <Col span={6}>
                  <ListApplicant 
                    type="interview"
                    loading={getApplicant.loading} 
                    dataSource={listData}
                    idApplicant={idApplicant}
                    selectedApplicant={(value) => this.selectedApplicant(value)}
                    handleInfiniteOnLoad={() => this.handleInfiniteOnLoad()} 
                  />
                </Col>
                <Col span={18}>
                  <Card>
                    {
                      dataApplicant ? 
                      <ContentInterview 
                        dataApplicant={dataApplicant}
                        isResult={isResult}
                        openModalProfile={this.openModalProfile}
                        onFinish={this.onFinish} 
                        formRef={this.formRef} 
                        openModal={this.openModal}
                        onCancelInterview={this.onCancelInterview}
                        handleChangeAssessmentResult={(e) => this.handleChangeAssessmentResult(e)}
                      />
                      : 
                      <Empty description={<Text>Please click an applicant name to start the assessment</Text>} />
                    }
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* Modal */}
        <Modal
          width={1000}
          destroyOnClose
          visible={visible}
          title="Edit Schedule"
          onCancel={this.onCancel}
          footer={[
            <Button className="btn-save-primary" key="submit" type="primary" onClick={this.onSubmit} disabled={!valueForm}>Update</Button>
          ]}
        >
          <EditInterview 
            {...this.props} 
            isMethod={isMethod} 
            formRef={this.formRef} 
            onSubmit={this.onSubmit}
            handleChangeValue={this.handleChangeValue}
            handleChangeMethod={(e) => this.handleChangeMethod(e)}
          />
        </Modal>
        <Modal 
          centered
          width={1000}
          title="Applicant Profile"
          visible={visibleProfile}
          onCancel={this.onCancelProfile}
          footer={[
            <Button className="btn-save-primary" key="back" type="primary" onClick={this.onCancelProfile}>Okay</Button>
          ]}
        >
          <div style={{ height: 600, overflowY: 'auto', overflowX: 'hidden' }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetApplicant, unmountGetProfile } = this.props;
    unmountGetApplicant()
    unmountGetDetail()
    unmountGetProfile()
  }
}

const mapStateToProps = (state) => ({
  getData       : state.jobs.activeApplicant,
  getApplicant  : state.jobs.listApplicant,
  getInterview  : state.jobs.detailInterview,
  getProfile    : state.candidate.detail,
})

const mapDispatchToProps = {
  actionInactive            : inactiveJob,
  actionUpdateInterview     : editInterview,
  actionAddOffer            : addOffer,
  actionCancelInterview     : cancelApplicant,
  actionDetailApplicant     : detailApplicant,
  actionGetApplicant        : listApplicant,
  actionGetProfile          : detailCandidate,
  actionGetDetail           : detailJobActiveApplicant,
  actionGetDetailInterview  : detailJobInterview,
  unmountGetApplicant       : unmountListApplicant,
  unmountGetProfile         : unmountDetailCandidate,
  unmountGetDetail          : unmountDetailJobsActiveApplicant,
  unmountGetDetailInterview : unmountDetailJobsInterview
}   

export default connect(mapStateToProps, mapDispatchToProps)(DetailInterviewEmployerJobs)