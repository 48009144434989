import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import ViewProfile from '../../Profile';
import HeaderDetailJob from '../../Header';
import { columns } from './columns';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading, FilterDetailHired } from '../../../../../../components';
import { SoundOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { detailCandidate, unmountDetailCandidate } from '../../../../../../redux/actions/candidate/candidateAction';
import { Row, Col, Breadcrumb, PageHeader, Card, Typography, Menu, Modal, message, Table, Pagination, Button } from 'antd';
import { detailJobActiveApplicant, inactiveJob, listApplicant, unmountDetailJobsActiveApplicant, unmountListApplicant } from '../../../../../../redux/actions/jobs/jobsAction'
const { Text } = Typography;
const { confirm } = Modal;

class DetailHiredAgencyJobs extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      url: 'job_agency',
      checked: true,
      visible: false,
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        id: null,
        phaseId: null,
        startDate: null,
        endDate: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }
  
  componentDidMount() {
    const { meta, url } = this.state;
    const { actionGetApplicant, actionGetDetail, match: { params } } = this.props;
    meta.id = params.id
    meta.phaseId = params.phaseId
    actionGetApplicant(meta, url)
    actionGetDetail(params.id, this.state.url)
  }

  handleChangeStatus = () => {
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    let self = this
    confirm({
      title: 'Are you sure to set it as inactive?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, self.state.url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, self.state.url)
        })
      },
    });
  }

  handleMenuPhase = (item) => {
    const { getData: { data } } = this.props;
    switch (item.title) {
      case "Applied":
        return this.props.history.push(`/agency/jobs/detail/${data.id}/active-applicants/${item.phaseCode}`);
      case "Qualified":
        return this.props.history.push(`/agency/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}`);
      case "Interview":
        return this.props.history.push(`/agency/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}/interview`);
      case "Offered":
        return this.props.history.push(`/agency/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}`);
      case "Hired":
        return this.props.history.push(`/agency/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}`);
      default:
        break;
    }
  }

  onRangePicker = value => {
    const { meta, url } = this.state;
    const { actionGetApplicant } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetApplicant(meta, url)
  }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionGetApplicant } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetApplicant(meta, url)
  }

  pagination = (page, perpage) => {
    const { meta, url } = this.state;
    const { actionGetApplicant } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetApplicant(meta, url)
  }

  onPriviewProfile = (id) => {
    const { actionGetProfile } = this.props;
    return actionGetProfile(id, () => {
      this.setState({ visible: true })
    })
  }

  onPriviewNotes = (record) => {
    Modal.info({
      title: 'Notes',
      content: (
        <Text>
          {record.notes}
        </Text>
      ),
      onOk() {},
    });
  }

  onCancel = () => {
    this.setState({ visible: false })
  }

  render() {
    const { checked, visible } = this.state;
    const { getData: { data, loading }, getApplicant } = this.props;
    if(loading){
      return <Loading />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 8]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SoundOutlined /> Job Posting</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/agency/jobs">Job Posting List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Text strong>
                  {data.jobNumber}  
                </Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Job Posting Detail' />
          </Col>
          {/* Card Header */}
          <Col span={24}>
            <HeaderDetailJob 
              data={data} 
              checked={checked} 
              handleChangeStatus={(e) => this.handleChangeStatus(e)} 
            />
          </Col>
          {/* Menu */}
          <Col span={24}>
            <Menu defaultSelectedKeys={['2']} mode="horizontal" style={{ background: 'none'  }}>
              <Menu.Item key="1">
                <Link to={`/agency/jobs/detail/${data.id}/information`}>Information</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={`/agency/jobs/detail/${data.id}/active-applicants/PT00`}>Active Applicants</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to={`/agency/jobs/detail/${data.id}/rejected-applicants`}>Rejected Applicants</Link>
              </Menu.Item>
            </Menu>
          </Col>
          {/* Content */}
          <Col span={24}>
            <Card title="Active Applicants">
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Menu defaultSelectedKeys={['4']} mode="horizontal" style={{ background: 'none'  }}>
                    <Menu.Item key={'0'}>
                      <Link to={`/agency/jobs/detail/${data.id}/active-applicants/PT00`}>Applied</Link>
                    </Menu.Item>
                    {
                      data.listPhase.map((item, i) => (
                        <Menu.Item key={item.sortOrder} onClick={() => this.handleMenuPhase(item)}>
                          <Link key={i} to="#">
                            {item.title} 
                          </Link>
                        </Menu.Item>
                      ))
                    }
                  </Menu>
                </Col>
                {/* Filter */}
                <Col span={24}>
                  <FilterDetailHired 
                    onSearch={this.onSearch} 
                    onRangePicker={this.onRangePicker}
                    tooltopRangePicker='Filter by Join Date'
                  />
                </Col>
                <Col span={24}>
                  {/* Table */}
                  <Table 
                    style={{ marginBottom: 20 }}
                    size="small"
                    rowKey={(i) => i.id} 
                    dataSource={getApplicant.data} 
                    loading={getApplicant.loading} 
                    columns={columns(this.onPriviewProfile, this.onPriviewNotes)} 
                    pagination={false}
                    scroll={{ x: 900 }}
                  />
                  {/* Pagination */}
                  <Pagination
                    total={getApplicant.pagination.total}
                    onChange={this.pagination}
                    current={getApplicant.pagination.page}
                    pageSize={getApplicant.pagination.perpage}
                    showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal 
          centered
          width={1000}
          visible={visible}
          title="Applicant Profile"
          onCancel={this.onCancel}
          footer={
            <Button className="btn-save-primary" key="back" type="primary" onClick={this.onCancel}>Okay</Button>
          }
        >
          <div style={{ height: 600, overflowY: 'auto', overflowX: 'hidden' }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetApplicant, unmountGetProfile } = this.props;
    unmountGetApplicant()
    unmountGetDetail()
    unmountGetProfile()
  }
}

const mapStateToProps = (state) => ({
  getData       : state.jobs.activeApplicant,
  getApplicant  : state.jobs.listApplicant,
  getProfile    : state.candidate.detail
})

const mapDispatchToProps = {
  actionInactive        : inactiveJob,
  actionGetApplicant    : listApplicant,
  actionGetProfile      : detailCandidate,
  actionGetDetail       : detailJobActiveApplicant,
  unmountGetApplicant   : unmountListApplicant,
  unmountGetProfile     : unmountDetailCandidate,
  unmountGetDetail      : unmountDetailJobsActiveApplicant
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailHiredAgencyJobs)
