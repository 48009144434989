import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Typography, Divider } from 'antd';
const { Text } = Typography;

export default class ContentModal extends Component {
  render() {
    const { visible, valueForm } = this.props;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24} style={{ marginBottom: 20 }}>
            <Text>Interview schedule detail:</Text>
          </Col>
          <Col span={24} style={{ marginBottom: 20 }}>
            {
              visible ?
              <React.Fragment>
                <Row gutter={16}>
                  <Col span={4}>
                    <Text>Date</Text>
                  </Col>
                  <Col span={20}>
                    <Text strong>: {valueForm.interviewDate ? moment(valueForm.interviewDate, 'DD/MM/YYYY').format('DD MMM YYYY') : null}</Text>
                  </Col>
                  <Col span={4}>
                    <Text>Time</Text>
                  </Col>
                  <Col span={20}>
                    <Text strong>: {valueForm.interviewTime ? valueForm.interviewTime : null }</Text>
                  </Col>
                  <Col span={4}>
                    <Text>Method</Text>
                  </Col>
                  <Col span={20}>
                    <Text strong>: {valueForm.isMethod ? "Direct" : "Virtual"}</Text>
                  </Col>
                  {
                    valueForm.isMethod &&
                    <React.Fragment>
                      <Col span={4}>
                        <Text>Location</Text>
                      </Col>
                      <Col span={20}>
                        <Text strong>: {valueForm.location ? valueForm.location : null}</Text>
                      </Col>
                    </React.Fragment>
                  }
                  {
                    !valueForm.isMethod &&
                    <React.Fragment>
                      <Col span={4}>
                        <Text>Virtual Link</Text>
                      </Col>
                      <Col span={20}>
                        <Text strong>: {valueForm.virtualLink ? valueForm.virtualLink : null}</Text>
                      </Col>
                    </React.Fragment>
                  }
                  <Col span={4}>
                    <Text>Notes</Text>
                  </Col>
                  <Col span={20}>
                    <Text strong>: {valueForm.notes ? valueForm.notes : null}</Text>
                  </Col>
                </Row>
              </React.Fragment>
              :
              null
            }
          </Col>
          <Divider />
          <Col span={24} style={{ marginBottom: 20 }}>
            <Text strong>
              By doing this, the interview schedule will be published
              to the applicant. Are you sure?
            </Text>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
