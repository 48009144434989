import axios from 'axios';
import { env } from './index';
import Cookie from 'js-cookie';

export const GET = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest',
    'X-Authorization': `Bearer ${getToken}`,
  }
  return new Promise((resolve ,reject) => {
    axios.get(`${env}${path}`, {
      headers: header,
      params: payload
    }).then((response) => {
      let data = response.data;
      if(response.status === 200){
        return resolve(data);
      }else{
        const error = { message: 'error' }
        return reject(error)
      }
    }).catch((err) => {
      return reject(err.response.data.message)
    })
  })
}

export const GET_DOWNLOAD = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest',
    'X-Authorization': `Bearer ${getToken}`,
  }
  return new Promise((resolve ,reject) => {
    axios.get(`${env}${path}`, {
      headers: header,
      params: payload,
      responseType: 'blob'
    }).then((response) => {
      let data = response.data;
      if(response.status === 200){
        return resolve(data);
      }else{
        const error = { message: 'error' }
        return reject(error)
      }
    }).catch((err) => {
      return reject(err.response.data.message)
    })
  })
}

export const POST_LOGIN = (path, payload) => {
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_LOGIN_NO_WITH_BEARER = (path, payload) => {
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header,
      params: payload
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_WITH_PARAMS = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Authorization': `Bearer ${getToken}`
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header,
      params: payload
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_NO_PARAMS = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Authorization': `Bearer ${getToken}`
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_FORM_DATA = (path, payload) => {
  const getToken = Cookie.get('user');
  return new Promise((resolve, reject) => {
    const data = new FormData();
    for (const [val, name] of Object.entries(payload)) {
      data.append(val, name);
    }
    axios.post(`${env}${path}`, data, {
      headers: {
        'Content-Type' : 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache',
        'X-Authorization': `Bearer ${getToken}`
      },
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  }) 
}

export const POST_FORM_DATA_ARRAY = (path, payload) => {
  const getToken = Cookie.get('user');
  return new Promise((resolve, reject) => {
    const data = new FormData();
    for (const [index, name] of Object.entries(payload)) {
      data.append(`listImage[${index}].isCover`, name.isCover );
      if(name.file !== undefined){
        data.append(`listImage[${index}].file`, name.file );
      }
      if(name.fileName !== undefined){
        data.append(`listImage[${index}].fileName`, name.fileName );
        data.append(`listImage[${index}].fileExtension`, name.fileExtension );
        data.append(`listImage[${index}].fileSize`, name.fileSize );
      }
    }
    axios.post(`${env}${path}`, data, {
      headers: {
        'Content-Type' : 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache',
        'X-Authorization': `Bearer ${getToken}`
      },
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  }) 
}