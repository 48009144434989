import React, { Component } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Row, Col, Space, Avatar, Typography, Button, Divider, Form, Radio, DatePicker, Input } from 'antd';
const { Title, Text } = Typography;

export default class ContentOffered extends Component {

  onFinish = (values) => {
    const { onFinish } = this.props;
    return onFinish(values)
  }

  render() {
    const { dataApplicant, openModalProfile, handleChangeAssessmentResult, isResult, formRef } = this.props;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={17}>
            <Space size="large">
              <Avatar size={64} src={dataApplicant.profilePic ? dataApplicant.profilePic : null } icon={!dataApplicant.profilePic ? <UserOutlined /> : null} />
              <div>
                <Title level={4}>{dataApplicant.name}</Title>
                <Text>{dataApplicant.gender ? dataApplicant.gender === 'FEMALE' ? 'Female' : 'Male' : 'N/A' }, {dataApplicant.age ? `${dataApplicant.age} years` : 'N/A'}</Text>
              </div>
            </Space>
          </Col>
          <Col span={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button className="btn-color-blue" onClick={openModalProfile}>
              View Profile
            </Button>
          </Col>
        </Row>
        <Divider />
        <Form onFinish={this.onFinish} layout="vertical" ref={formRef}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Notes of Interview Stage:">
                <Text strong>{dataApplicant.notes ? dataApplicant.notes : 'N/A'}</Text>
              </Form.Item>
              <Divider dashed />
            </Col>

            <Col span={12}>
              <Form.Item name="isResult" label="Assessment Result" initialValue={isResult}>
                <Radio.Group onChange={handleChangeAssessmentResult}>
                  <Radio value={true}>Hire</Radio>
                  <Radio value={false}>Reject</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {
              isResult ?
              <Col span={12}>
                <Form.Item 
                  label="Join Date"
                  name="joinDate"
                  rules={[
                    { required: true, message: 'This is a required field' },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              :
              null
            }
            <Col span={24}>
              <Form.Item name="notes" label="Notes (optional)" initialValue={null}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button className="btn-save-primary" type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
