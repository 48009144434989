import React, { Component } from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';
import ContentOffered from './Content';
import ViewProfile from '../../Profile';
import HeaderDetailJob from '../../Header';
import ListApplicant from '../../ListApplicant';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../../components';
import { SoundOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { detailCandidate, unmountDetailCandidate } from '../../../../../../redux/actions/candidate/candidateAction';
import { Row, Col, Breadcrumb, PageHeader, Card, Typography, Menu, Modal, message, Empty, Input, Button, AutoComplete } from 'antd';
import { detailJobActiveApplicant, addHired, inactiveJob, listApplicant, unmountDetailJobsActiveApplicant, unmountListApplicant } from '../../../../../../redux/actions/jobs/jobsAction';
const { Text } = Typography;
const { confirm } = Modal;

class DetailOfferedEmployerJobs extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      url: 'job_employers',
      method: "VIRTUAL",
      listData: [],
      checked: true,
      visible: false,
      valueForm: null,
      idApplicant: null,
      dataApplicant: null,
      isResult: true,
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        id: null,
        phaseId: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta, url } = this.state;
    const { actionGetApplicant, actionGetDetail, match: { params } } = this.props;
    meta.id = params.id
    meta.phaseId = params.phaseId
    actionGetApplicant(meta, url, (response) => {
      this.setState({ listData: response.data })
    })
    actionGetDetail(params.id, url)
  }

  handleChangeStatus = () => {
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    let self = this
    confirm({
      title: 'Are you sure to set it as inactive?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, self.state.url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, self.state.url)
        })
      },
    });
  }

  handleMenuPhase = (item) => {
    const { getData: { data } } = this.props;
    switch (item.title) {
      case "Applied":
        return this.props.history.push(`/employer/jobs/detail/${data.id}/active-applicants/${item.phaseCode}`);
      case "Qualified":
        return this.props.history.push(`/employer/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}`);
      case "Interview":
        return this.props.history.push(`/employer/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}/interview`);
      case "Offered":
        return this.props.history.push(`/employer/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}`);
      case "Hired":
        return this.props.history.push(`/employer/jobs/detail/${data.id}/active-applicants/${item.phaseCode}/${item.phaseId}`);
      default:
        break;
    }
  }

  handleChangeAssessmentResult = (e) => {
    this.setState({ isResult: e.target.value })
  }

  selectedApplicant = (value) => {
    const { actionGetProfile } = this.props;
    const { dataApplicant } = this.state;
    this.setState({ idApplicant: value.id, dataApplicant: value })
    if(dataApplicant){
      this.formRef.current.setFieldsValue({
        isResult: true,
        notes: null,
      });
    }
    actionGetProfile(value.candidateId)
  }

  onFinish = (values) => {
    const self = this;
    const { dataApplicant, meta, url } = this.state;
    const { match: { params }, actionAddHired, actionGetApplicant } = this.props;
    values.phaseId = Number(params.phaseId)
    values.jobAppliedId = dataApplicant.id
    values.joinDate = moment(values.joinDate).format('DD/MM/YYYY')
    if(values.isResult){
      confirm({
        title: 'This is the last step of the hiring process.',
        icon: <ExclamationCircleOutlined />,
        content: 'By doing this, the applicant will be notified of the application status being changed to hired. Are you sure?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          return actionAddHired(values, () => {
            message.success('Successfully moved')
            actionGetApplicant(meta, url, (response) => {
              self.setState({ listData: response.data, dataApplicant: null })
            })
          })
        },
      });
    }else {
      confirm({
        title: 'This application will be moved to rejected.',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          return actionAddHired(values, () => {
            message.success('Successfully moved')
            actionGetApplicant(meta, url, (response) => {
              self.setState({ listData: response.data, dataApplicant: null })
            })
          })
        },
      });
    }
  }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionGetApplicant } = this.props;
    meta.search = value === '' ? null : value
    return actionGetApplicant(meta, url, (response) => {
      this.setState({ listData: response.data })
    })
  }

  openModalProfile = () => {
    this.setState({ visible: true })
  }

  onCancel = () => {
    this.setState({ visible: false })
  }

  handleInfiniteOnLoad = () => {
    ('scroll');
  };

  render() {
    const { checked, idApplicant, dataApplicant, visible, listData, isResult } = this.state;
    const { getData: { data, loading }, getApplicant } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SoundOutlined /> Job Posting</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/employer/jobs">Job Posting List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Text strong>
                  {data.jobNumber}  
                </Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Job Posting Detail' />
          </Col>
          {/* Card Header */}
          <Col span={24}>
            <HeaderDetailJob 
              data={data} 
              checked={checked} 
              handleChangeStatus={(e) => this.handleChangeStatus(e)} 
            />
          </Col>
          {/* Menu */}
          <Col span={24}>
            <Menu defaultSelectedKeys={['2']} mode="horizontal" style={{ background: 'none'  }}>
              <Menu.Item key="1">
                <Link to={`/employer/jobs/detail/${data.id}/information`}>Information</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={`/employer/jobs/detail/${data.id}/active-applicants/PT00`}>Active Applicants</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to={`/employer/jobs/detail/${data.id}/rejected-applicants`}>Rejected Applicants</Link>
              </Menu.Item>
            </Menu>
          </Col>
          {/* Content */}
          <Col span={24}>
            <Card title="Active Applicants">
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Menu defaultSelectedKeys={['3']} mode="horizontal" style={{ background: 'none'  }}>
                    <Menu.Item key={0}>
                      <Link to={`/employer/jobs/detail/${data.id}/active-applicants/PT00`}>Applied</Link>
                    </Menu.Item>
                    {
                      data.listPhase.map((item, i) => (
                        <Menu.Item key={item.sortOrder} onClick={() => this.handleMenuPhase(item)}>
                          <Link key={i} to="#">
                            {item.title} 
                          </Link>
                        </Menu.Item>
                      ))
                    }
                  </Menu>
                </Col>
                <Col span={24}>
                  <AutoComplete
                    onSearch={this.onSearch} 
                    style={{ width: '100%' }} 
                    allowClear
                  >
                    <Input 
                      prefix={<SearchOutlined />} 
                      style={{ width: '28%'}} 
                      placeholder="Search by Applicant Name" 
                    />
                  </AutoComplete>
                </Col>
                <Col span={24}>
                  <Text>Showing {getApplicant.pagination.total} Data</Text>
                </Col>
                <Col span={6}>
                  <ListApplicant 
                    type="offered"
                    loading={getApplicant.loading} 
                    dataSource={listData}
                    idApplicant={idApplicant}
                    selectedApplicant={(e) => this.selectedApplicant(e)}
                    handleInfiniteOnLoad={() => this.handleInfiniteOnLoad()} 
                  />
                </Col>
                <Col span={18}>
                  <Card>
                    {
                      dataApplicant ? 
                      <ContentOffered 
                        dataApplicant={dataApplicant}
                        openModalProfile={this.openModalProfile}
                        onFinish={this.onFinish}
                        isResult={isResult}
                        formRef={this.formRef}
                        handleChangeAssessmentResult={(e) => this.handleChangeAssessmentResult(e)}
                      />
                      : 
                      <Empty description={<Text>Please click an applicant name to start the assessment</Text>} />
                    }
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal 
          centered
          width={1000}
          visible={visible}
          title="Applicant Profile"
          onCancel={this.onCancel}
          footer={[
            <Button className="btn-save-primary" key="back" type="primary" onClick={this.onCancel}>Okay</Button>
          ]}
        >
          <div style={{ height: 700, overflowY: 'auto', overflowX: 'hidden' }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetApplicant } = this.props;
    unmountGetApplicant()
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData       : state.jobs.activeApplicant,
  getApplicant  : state.jobs.listApplicant,
  getProfile    : state.candidate.detail
})

const mapDispatchToProps = {
  actionInactive        : inactiveJob,
  actionAddHired        : addHired,
  actionGetApplicant    : listApplicant,
  actionGetProfile      : detailCandidate,
  actionGetDetail       : detailJobActiveApplicant,
  unmountGetApplicant   : unmountListApplicant,
  unmountGetProfile     : unmountDetailCandidate,
  unmountGetDetail      : unmountDetailJobsActiveApplicant
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailOfferedEmployerJobs)