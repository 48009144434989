import { ADMIN } from '../permissions'

export function convertMenu( listMenu, role, cb ){
  return sliceMenu(listMenu, 0, (res) => {
    sliceMenu(ADMIN, listMenu.length, (_res) => {
      var publicMenu = _res;
      var mainMenu = res;
      var merge = role === 'ROLE_ADMIN_JOBSHUB' ? publicMenu : merge_array(publicMenu, mainMenu) ;
      return cb(merge);
    })
  })
}

export function sliceMenu( listMenu, count, cb ){
  var newMenu = [];
  listMenu.forEach((res, i) => {
    var lm = res;
    lm.index = i;
    newMenu.push(lm);
    return sliceSubMenu(res, i, (res_) => {
      res_.forEach(aa => newMenu.push(aa) )
    })
  });
  return cb(newMenu)
} 

export function sliceSubMenu(listMenu, i, cb){
  var qq = [];
  if(listMenu.subMenu){
    listMenu.subMenu.forEach(res_ => {
      var aa = res_;
      aa.index = i;
      aa.icon = listMenu.icon;
      qq.push(aa);
    });
    return cb(qq);
  }
}

export function merge_array(array1, array2) {
  var result_array = [];
  var arr = array1.concat(array2);
  var len = arr.length;
  var assoc = {};
  while(len--) {
    var checkComp = arr[len].component;
    var item = arr[len];
    if(!assoc[checkComp]) { 
        result_array.unshift(item);
        assoc[checkComp] = true;
    }
  }
  return result_array;
}