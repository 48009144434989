import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined, DatabaseOutlined } from '@ant-design/icons';
import { createEducationLevel } from '../../../../../redux/actions/master/educationLevel/educationLevelAction';
import { Col, Form, Row, Input, Button, Space, Select, Divider, PageHeader, message, Card, Breadcrumb } from 'antd';

class CreateMasterEducationLevel extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
    }
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    this.setState({ submitLoading: true })
    return actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/master/education-levels')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading } = this.state;
    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} layout="vertical">
          <Row gutter={[16, 16]}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/education-levels">
                    Master Data List Education Levels
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Education Levels</Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader className="site-page-header" title="Create a Education Level" />
            </Col>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>

                  <Col span={12}>
                    <Form.Item 
                      label="Education Level Name" 
                      name="name" 
                      validateFirst
                      initialValue=''
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: '50 characters only' },
                        { pattern: new RegExp("^[a-zA-Z/ ]+$"), message: 'Only symbol ” / “ is allowed' },
                        { pattern: new RegExp(/^([^0-9]*)$/), message: 'Number and special character are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled" 
                      initialValue={true}
                      tooltip={{ 
                        title: 'Inactive means this education level isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Description" 
                      name="description" 
                      initialValue=""
                      rules={[
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Divider />
                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Space>
                      <Button className="btn-save-primary" type="default"onClick={() => window.history.back()}>Cancel</Button>
                      <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
  actionCreate: createEducationLevel,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMasterEducationLevel)
