import React, { Component } from 'react';
import moment from 'moment';
import HeaderDetailJob from '../../../../Agency/Jobs/Detail/Header';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import { SoundOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { detailJob, inactiveJob, unmountDetailJobs } from '../../../../../redux/actions/jobs/jobsAction';
import { Row, Col, Breadcrumb, PageHeader, Card, Typography, Tag, Descriptions, Menu, Modal, Divider, message } from 'antd';
const { Text } = Typography;
const { confirm } = Modal;

class DetailInformationAgencyJobs extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      url: 'job_agency',
      checked: true
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id, this.state.url)
  }

  handleChangeStatus = () => {
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    let self = this
    confirm({
      title: 'Are you sure to set it as inactive?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, self.state.url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, self.state.url)
        })
      },
    });
  }

  render() {
    const { checked } = this.state;
    const { getData: { data, loading } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SoundOutlined /> Job Posting</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/agency/jobs">Job Posting List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Text strong>
                  {data.jobNumber}  
                </Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Job Posting Detail' />
          </Col>
          {/* Card Header */}
          <Col span={24}>
            <HeaderDetailJob 
              data={data} 
              checked={checked} 
              handleChangeStatus={(e) => this.handleChangeStatus(e)} 
            />
          </Col>
          {/* Menu */}
          <Col span={24}>
            <Menu defaultSelectedKeys={['1']} mode="horizontal" style={{ background: 'none'  }}>
              <Menu.Item key="1">
                <Link to={`/agency/jobs/detail/${data.id}/information`}>Information</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={`/agency/jobs/detail/${data.id}/active-applicants/PT00`}>Active Applicants</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to={`/agency/jobs/detail/${data.id}/rejected-applicants`}>Rejected Applicants</Link>
              </Menu.Item>
            </Menu>
          </Col>
          {/* Information */}
          <Col span={24}>
            <Card title="Information">
              <Row gutter={16}>
                <Col span={24}>
                  <Text style={{ fontSize: 16 }}>Client Information</Text>
                </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 20 }}>
                    <Descriptions.Item label={<Text type="secondary">Name</Text>}>
                      {data.clientName ? data.clientName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person Name</Text>}>
                      {data.clientPic ? data.clientPic : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Divider dashed={true} />
                <Col span={24}>
                  <Text style={{ fontSize: 16 }}>Job Opening Information</Text>
                </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 20 }}>
                    <Descriptions.Item label={<Text type="secondary">Job Posting Title</Text>}>
                      {data.title ? data.title : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Work from home (remote)</Text>}>
                      {data.isRemote ? 'Yes' : 'No'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Payment Type</Text>}>
                      {
                        data.paymentType ?
                          <React.Fragment>
                            {data.paymentType === "DAILY" ? "Per Day" : null}
                            {data.paymentType === "WEEKLY" ? "Per Week" : null}
                            {data.paymentType === "MONTHLY" ? "Per Month" : null}
                            {data.paymentType === "YEARLY" ? "Per Year" : null}
                            {data.paymentType === "PROJECT" ? "Per Project" : null}
                          </React.Fragment>
                        : 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Job Industry</Text>}>
                      {data.jobIndustryName ? data.jobIndustryName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Experience Level</Text>}>
                      {
                        data.experienceLevel ?
                          <React.Fragment>
                            {data.experienceLevel === "ENTRY_LEVEL" ? "Entry Level" : null}
                            {data.experienceLevel === "ASSOCIATE" ? "Associate" : null}
                            {data.experienceLevel === "MID_SENIOR" ? "Mid-Senior Level" : null}
                            {data.experienceLevel === "DIRECTOR" ? "Director" : null}
                            {data.experienceLevel === "EXECUTIVE" ? "Executive" : null}
                          </React.Fragment>
                        : 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Salary Type</Text>}>
                      {data.isFixSalary ? "Fix Salary" : "Range"}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Job Position</Text>}>
                      {data.jobPositionName ? data.jobPositionName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Number of Position</Text>}>
                      {data.numberOfPosition ? data.numberOfPosition : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Payroll Currency</Text>}>
                      {data.currencyCode ? data.currencyCode : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Job Type</Text>}>
                      {
                        data.jobType ?
                          <React.Fragment>
                            {data.jobType === "FULL_TIME" ? "Full Time" : null}
                            {data.jobType === "PART_TIME" ? "Part Time" : null}
                            {data.jobType === "FREELANCE" ? "Freelance" : null}
                            {data.jobType === "INTERNSHIP" ? "Internship" : null}
                          </React.Fragment>
                        : 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Date Opened</Text>}>
                      {data.dateOpened ? moment(data.dateOpened, 'DD/MM/YYYY').format("DD MMMM YYYY") : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Salary</Text>}>
                      {
                        data.isFixSalary ? 
                        <React.Fragment>
                          {data.salary ? data.salary.toLocaleString() : 'N/A'}
                        </React.Fragment>
                        : 
                        <React.Fragment>
                          {data.salary ? data.salary.toLocaleString() : 'N/A'} - {data.maxSalary ? data.maxSalary.toLocaleString() : 'N/A'}
                        </React.Fragment>
                      }
                    </Descriptions.Item>
                  </Descriptions>

                  <Descriptions layout="vertical" column={3} size="small">
                    <Descriptions.Item label={<Text type="secondary">Placement Type</Text>}>
                      {
                        data.placementType ?
                          <React.Fragment>
                            {data.placementType === "DOMESTIC" ? "Domestic" : null}
                            {data.placementType === "INTERNATIONAL" ? "International" : null}
                          </React.Fragment>
                        : 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Close Application Date</Text>}>
                      {data.targetDate ? moment(data.targetDate, 'DD/MM/YYYY').format("DD MMMM YYYY") : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>

                  <Descriptions layout="vertical" column={3} size="small">
                    <Descriptions.Item label={<Text type="secondary">Job Placement</Text>}>
                      {data.jobDestinationName ? data.jobDestinationName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Display salary information</Text>}>
                      {data.isDisplaySalary ? "Yes" : "No"}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Divider dashed={true} />
                <Col span={24}>
                  <Text style={{ fontSize: 16 }}>Description Information</Text>
                </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={1} size="small" style={{ marginTop: 20 }}>
                    <Descriptions.Item label={<Text type="secondary">Job Description</Text>}>
                      {
                        data.jobDescription ?
                        <div dangerouslySetInnerHTML={{ __html: data.jobDescription }}></div>
                        :
                        'N/A'
                      }
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={24}>
                  {
                    data.placementType === "DOMESTIC" ?
                    <React.Fragment>
                      <Descriptions layout="vertical" column={1} size="small">
                        <Descriptions.Item label={<Text type="secondary">Requirements</Text>}>
                          {
                            data.jobRequirement ?
                            <div dangerouslySetInnerHTML={{ __html: data.jobRequirement }}></div>
                            :
                            'N/A'
                          }
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Benefits</Text>}>
                          {
                            data.jobRequirement ?
                            <div dangerouslySetInnerHTML={{ __html: data.benefit }}></div>
                            :
                            'N/A'
                          }
                        </Descriptions.Item>
                      </Descriptions>
                    </React.Fragment>
                    : null
                  }
                  {
                    data.placementType === "INTERNATIONAL" ?
                    <React.Fragment>
                      <Divider dashed={true} />
                      <Text style={{ fontSize: 16 }}>Terms and Conditions</Text>
                      <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 20 }}>
                        <Descriptions.Item label={<Text type="secondary">Duration of contract</Text>}>
                          {data.durationOfContract ? data.durationOfContract : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Paid Annual Leave</Text>}>
                          {data.isPaidAnnualLeave ? "Yes" : 'No'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Probation Period (months)</Text>}>
                          {data.probationPeriod ? data.probationPeriod : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Contract is extendable</Text>}>
                          {data.isContractExtendable ? "Yes" : 'No'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Working Hours (per day)</Text>}>
                          {data.workingHour ? data.workingHour : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Working Overtime</Text>}>
                          {data.isWorkingOvertime ? "Yes" : 'No'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Annual Leave (days)</Text>}>
                          {data.annualLeave ? data.annualLeave : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Working Days (per week)</Text>}>
                          {data.workingDay ? data.workingDay : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Other Working System</Text>}>
                          {data.otherWorkingSystem ? data.otherWorkingSystem : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      {
                        data.listBenefit.length > 0 ?
                        <React.Fragment>
                          <Divider dashed={true} />
                          <Text style={{ fontSize: 16 }}>Benefits</Text>
                          <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 20 }}>
                            {
                              data.listBenefit && data.listBenefit.map((item, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <Descriptions.Item label={<Text type="secondary">{item.name}</Text>}>
                                      {
                                        item.status ? 
                                        <Text>
                                          Allowance, {data.currencyCode} {item.allowance ? item.allowance.toLocaleString() : 'N/A'}
                                        </Text>
                                        :
                                        <Text>
                                          Provided, no amount
                                        </Text>
                                      }
                                    </Descriptions.Item>
                                  </React.Fragment>
                                )
                              })
                            }
                          </Descriptions>
                        </React.Fragment>
                        : null
                      }
                      <Divider dashed={true} />
                      <Text style={{ fontSize: 16 }}>Candidate Requirements</Text>
                      <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 20 }}>
                        <Descriptions.Item label={<Text type="secondary">Gender</Text>}>
                          {data.gender === "BOTH" ? "Male and Female" : null}
                          {data.gender === "MALE" ? "Male" : null}
                          {data.gender === "Female" ? "Female" : null}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Minimum Body Height (cm)</Text>}>
                          {data.minHeight ? data.minHeight : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Age Range</Text>}>
                          {data.ageStart ? data.ageStart : 'N/A'} - {data.ageEnd ? data.ageEnd : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Minimum Experience (years)</Text>}>
                          {data.minExperience ? data.minExperience : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Minimum Body Weight (kg)</Text>}>
                          {data.minWeight ? data.minWeight : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Minimum Education Level</Text>}>
                          {data.educationLevelName ? data.educationLevelName : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Certification</Text>}>
                          {data.certification ? data.certification : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Notes</Text>}>
                          {data.notes ? data.notes : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>

                      <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 10 }}>
                        <Descriptions.Item label={<Text type="secondary">Field of Study</Text>}>
                          {data.fieldOfStudy ? data.fieldOfStudy : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>

                      <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 10 }}>
                        <Descriptions.Item label={<Text type="secondary">Skills</Text>}>
                          {
                            data.skill && data.skill.length > 0 ?
                            <>
                              {
                                data.skill.map((item, i) => (
                                  <Tag key={i}>{item}</Tag>
                                ))
                              }
                            </>
                            : 'N/A'
                          }
                        </Descriptions.Item>
                      </Descriptions>
                    </React.Fragment>
                    : null
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    return unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData: state.jobs.detail
})

const mapDispatchToProps = {
  actionInactive    : inactiveJob,
  actionGetDetail   : detailJob,
  unmountGetDetail  : unmountDetailJobs
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailInformationAgencyJobs)
