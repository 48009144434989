import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { columns } from './columns';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FilterMasterData } from '../../../../components';
import { Row, Col, Breadcrumb, PageHeader, Card, Menu, Table, Pagination, message } from 'antd';
import { disableVillage, enableVillage, listVillages, unmountListVillages } from '../../../../redux/actions/master/villages/villagesAction';
import { DatabaseOutlined, FlagOutlined, ApartmentOutlined, EnvironmentOutlined, PushpinOutlined, NumberOutlined, GroupOutlined, ClusterOutlined, BulbOutlined, ReadOutlined, ToolOutlined, GlobalOutlined, BankOutlined } from '@ant-design/icons';

class MasterVillages extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      sortedInfo: null,
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        flagStatus: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  onStatus = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.flagStatus = value
    return actionGetData(meta)
  }

  pagination = (page, perpage) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  handleEnable = (value) => {
    const { meta } = this.state;
    const { actionEnable, actionGetData } = this.props;
    return actionEnable(value, () => {
      message.success('Successfully changed to active')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  handleDisable = (value) => {
    const { meta } = this.state;
    const { actionDisable, actionGetData } = this.props;
    return actionDisable(value, () => {
      message.success('Successfully changed to inactive')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  onClickEdit = (id) => {
    this.props.history.push(`/master/villages/edit/${id}`)
  }

  render() {
    const { getData: { loading, data, pagination } } = this.props;

    const defaultStatusFilter = [
      { value: null, name: 'All' },
      { value: true, name: 'Active' },
      { value: false, name: 'Inactive' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
              <Breadcrumb.Item>Master Data List Villages</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Master Data List Villages' />
          </Col>
          { /* Filter */}
          <Col span={24}>
            <FilterMasterData 
              name="New Village" 
              link="/master/villages/create"
              placeholder="Search by Name"
              onSearch={this.onSearch} 
              onStatus={this.onStatus}
              fase2={{ key: 'status', value: defaultStatusFilter}}
            />
          </Col>
          { /* Menu Side Bar */}
          <Col span={6}>
            <Card>
              <Menu defaultSelectedKeys={['5']}>
                <Menu.Item key="1" icon={<FlagOutlined />}>
                  <Link to="/master/countries">Countries</Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<ApartmentOutlined />}>
                  <Link to="/master/provinces">Provinces</Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<EnvironmentOutlined />}>
                  <Link to="/master/cities">Cities</Link>
                </Menu.Item>
                <Menu.Item key="4" icon={<PushpinOutlined />}>
                  <Link to="/master/sub-districts">Sub-Districts (Kecamatan)</Link>
                </Menu.Item>
                <Menu.Item key="5" icon={<NumberOutlined />}>
                  <Link to="/master/villages">Villages (Kelurahan)</Link>
                </Menu.Item>
                <Menu.Item key="6" icon={<GroupOutlined />}>
                  <Link to="/master/job-industries">Job Industries</Link>
                </Menu.Item>
                <Menu.Item key="7" icon={<ClusterOutlined />}>
                  <Link to="/master/job-positions">Job Positions</Link>
                </Menu.Item>
                <Menu.Item key="8" icon={<GlobalOutlined />}>
                  <Link to="/master/job-destinations">Job Destinations</Link>
                </Menu.Item>
                <Menu.Item key="9" icon={<BulbOutlined />}>
                  <Link to="/master/education-levels">Education Levels</Link>
                </Menu.Item>
                <Menu.Item key="10" icon={<ReadOutlined />}>
                  <Link to="/master/fields-of-study">Fields Of Study</Link>
                </Menu.Item>
                <Menu.Item key="11" icon={<BankOutlined />}>
                  <Link to="/master/institutions">Institutions</Link>
                </Menu.Item>
                <Menu.Item key="12" icon={<ToolOutlined />}>
                  <Link to="/master/skills">Skills</Link>
                </Menu.Item>
              </Menu>
            </Card>
          </Col>
          { /* Table */}
          <Col span={18}>
            <Row gutter={[16, 24]}>
              <Col span={24}>
                <Table 
                  size="small"
                  loading={loading} 
                  dataSource={data}
                  pagination={false} 
                  rowKey={(i) => i.id} 
                  columns={columns(this.onClickEdit, this.handleEnable, this.handleDisable)}
                />
              </Col>
              {/* Pagination */}
              <Col span={24}>
                <Pagination
                  total={pagination.total}
                  onChange={this.pagination}
                  current={pagination.page}
                  defaultPageSize={pagination.perpage}
                  showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetData } = this.props;
    return unmountGetData()
  }
}

const mapStateToProps = (state) => ({
  getData: state.villages.list
})

const mapDispatchToProps = {
  actionGetData     : listVillages,
  actionEnable      : enableVillage,
  actionDisable     : disableVillage,
  unmountGetData    : unmountListVillages
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterVillages)

