import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Space, Typography } from 'antd';
const { Text } = Typography;

export const columns = (onPriviewProfile, onPriviewNotes) => {
  return [
    {
      title: 'Name',
      key: 'name',
      fixed: 'left',
      width: 50,
      render: (record) => (
        <Space direction="vertical">
          <Link to="#" onClick={() => onPriviewProfile(record.candidateId)} style={{ color: '#0076de' }}>
            {record.name ? record.name : "N/A"}
          </Link>
          <Text>{record.age ? record.age : "N/A"}, Male</Text>
        </Space>
      )
    },
    {
      title: 'Join Date',
      key: 'joinDate',
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>
          {record.joinDate ? record.joinDate : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Length of Experience',
      key: 'lengthOfExperience',
      width: 80,
      render: (record) => (
        <Text>
          {record.lengthOfExperience ? record.lengthOfExperience : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Last Job Title',
      key: 'lastJobTitle',
      width: 80,
      render: (record) => (
        <Space direction="vertical">
          <Text>{record.lastJobPosition ? record.lastJobPosition : 'N/A'}</Text>
          <Text>{record.lastJobCompany ? record.lastJobCompany : 'N/A'}</Text>
        </Space>
      )
    },
    {
      title: 'Notes',
      key: 'notes',
      width: 80,
      align: 'center',
      render: (record) => (
        <React.Fragment>
          {
            record.notes ?
            <Link to="#" onClick={() => onPriviewNotes(record)}>
              <Button className="btn-radius" type="primary">
                View
              </Button>
            </Link>
            :
            <Text>N/A</Text>
          }
        </React.Fragment>
      )
    },
  ]
}