import React, { Component } from 'react';
import moment from 'moment';
import ReactQuill from 'react-quill';
import debounce from 'lodash/debounce';
import 'react-quill/dist/quill.snow.css';
import update from 'react-addons-update';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { createJob, saveJob } from '../../../../redux/actions/jobs/jobsAction';
import { masterClients, unmountMasterClients } from '../../../../redux/actions/client/clientAction';
import { masterBenefit, unmountMasterBenefit } from '../../../../redux/actions/benefit/benefitAction';
import { positionByIndustry } from '../../../../redux/actions/master/jobPositions/jobPositionsAction';
import { listSkills, unmountListSkill } from '../../../../redux/actions/master/skills/skillsAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/master/countries/countriesAction';
import { listFieldOfStudy, unmountListFieldOfStudy } from '../../../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
import { SoundOutlined, InfoCircleOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { masterJobIndustries, unmountMasterJobIndustries } from '../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { masterEducationLevel, unmountMasterEducationLevel } from '../../../../redux/actions/master/educationLevel/educationLevelAction';
import { listJobDestinations, unmountListJobDestinations } from '../../../../redux/actions/master/jobDestinations/jobDestinationsAction';
import { Form, Card, Row, Col, Breadcrumb, PageHeader, Typography, Button, Input, Select, Radio, DatePicker, message, Divider, Space, Modal, AutoComplete, InputNumber, Spin } from 'antd';
const { confirm } = Modal;

class CreateAgencyJobs extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = { 
      benefit: [],
      allowance: [], 
      requirement: [], 
      valueForm: null,
      clientName: null,
      dataBenefit: null,
      isFixSalary: true,
      dataJobPosition: [],
      placementType: null,
      startDate: moment(),
      submitLoading: false,
      loadingSelect: false,
      isDisplaySalary: true,
      url: 'job_agency',
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaJobDestinations: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaSkills: {
        page: 1,
        perpage: 10,
        search: ''
      },
    }
    this.onSearchSkills = debounce(this.onSearchSkills.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
    this.onSearchJobDestinations = debounce(this.onSearchJobDestinations.bind(this), 800)
  }

  componentDidMount() {
    const { 
      actionGetClient, 
      actionGetBenefit,
      actionGetCountry, 
      actionGetEducation, 
      actionGetJobIndustries, 
    } = this.props;
    actionGetClient()
    actionGetJobIndustries()
    actionGetCountry()
    actionGetEducation()
    actionGetBenefit()
  }

  onChangeStartDate = (current) => {
    this.formRef.current.setFieldsValue({
      targetDate: null,
    });
    this.setState({ startDate: current })
  }

  disabledDate = (current) => {
    let yesterday = moment().subtract(1, 'days');
    return !current || current.isBefore(yesterday);
  }

  disabledEndDate = (current) => {
    const { startDate } = this.state;
    return current < startDate
  }

  handleChangeClient = (_, value) => {
    this.setState({ clientName: value.key })
  }

  // Handle Data Job Destination
  onSearchJobDestinations = (value) => {
    const { metaJobDestinations } = this.state;
    const { actionGetJobDestinations } = this.props;
    metaJobDestinations.page = 1
    metaJobDestinations.perpage = 10
    metaJobDestinations.search = value
    this.setState({ loadingSelect: true })
    return actionGetJobDestinations(metaJobDestinations, () => {
      return this.setState({ loadingSelect: false })
    })
  }

  onBlurJobDestinations = () => {
    const { unmountGetJobDestinantions } = this.props;
    return unmountGetJobDestinantions()
  }

  // Handle Data Field Of Study
  onSearchFieldOfStudy = (value) => {
    const { metaFieldOfStudy } = this.state;
    const { actionGetFieldOfStudy } = this.props;
    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value
    return actionGetFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionGetFieldOfStudy } = this.props;
    return actionGetFieldOfStudy(metaFieldOfStudy)
  }

  // Handle Data Skills
  onSearchSkills = (value) => {
    const { metaSkills } = this.state;
    const { actionGetSkill } = this.props;
    metaSkills.page = 1
    metaSkills.perpage = 10
    metaSkills.search = value
    return actionGetSkill(metaSkills)
  }

  onBlurSkills = () => {
    const { unmountGetSkill } = this.props;
    return unmountGetSkill()
  }

  // Handle Data Job Position
  handleChangeJobPosition = (value) => {
    const { actionGetJobPosition } = this.props;
    this.formRef.current.setFieldsValue({
      jobPositionId: null,
    });
    return actionGetJobPosition(value, (response) => {
      this.setState({ dataJobPosition: response })
    })
  }

  handlePlacementType = (value) => {
    this.setState({ 
      benefit: [],
      requirement: [],
      placementType: value
    })
    this.formRef.current.setFieldsValue({
      jobRequirement: null,
      benefit: null,
      durationOfContract: null,
      isContractExtendable: true,
      annualLeave: null,
      isPaidAnnualLeave: true,
      workingHour: null,
      workingDay: null,
      probationPeriod: null,
      isWorkingOvertime: true,
      otherWorkingSystem: null,
      listBenefit: [],
      gender: 'BOTH',
      ageStart: null, 
      ageEnd: null,
      educationLevelId: null,
      fieldOfStudy: null,
      skill: [],
      otherRequirements: [],
      minExperience: null,
      certification: null,
      minHeight: null,
      minWeight: null,
      notes: null
    });
  }

  handleDisplaySalary = (value) => {
    this.setState({ isDisplaySalary: value })
  }

  handleChangeFixSalary = (value) => {
    this.setState({ isFixSalary: value })
    this.formRef.current.setFieldsValue({
      salary: null,
      maxSalary: null
    });
  }

  addBenefit = () => {
    const { dataBenefit } = this.state;
    this.setState({
      benefit: update(this.state.benefit, {
        $push: [dataBenefit]
      }),
      dataBenefit: null
    })
  }

  handleChangeBenefit = (value) => {
    this.setState({ dataBenefit: value })
  }

  handleRemoveBenefit = (index) => {
    this.setState({
      benefit: update(this.state.benefit, {
        $splice: [[index, 1]]
      })
    })
  }

  handleChangeRequirement = (_, value) => {
    this.setState({ requirement: value })
  }

  handleChangeAllowance = (value, index) => {
    const { allowance } = this.state;
    const field = this.formRef.current.getFieldValue('listBenefit')
    Object.assign(field[index], { 'allowance': null })
    this.formRef.current.setFieldsValue({
      field
    });
    allowance[index] = value
    this.setState({ allowance })
  }

  onCancel = () => {
    const { history } = this.props;
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, you will lose all of your ‘create a job’ data and you can’t undo this action.',
      onOk() {
        return history.push('/agency/jobs')
      }
    });
  }

  handleChangeValue = (value) => {
    if(value.clientId && value.title && value.dateOpened && value.targetDate){
      this.setState({ valueForm: value })
    }
  }

  onSave = (values) => {
    const { url } = this.state;
    const { actionSave, history } = this.props;
    const valuesForm = values.getFieldsValue();
    valuesForm.dateOpened = valuesForm.dateOpened ? moment(valuesForm.dateOpened).format('DD/MM/YYYY') : null
    valuesForm.targetDate = valuesForm.targetDate ? moment(valuesForm.targetDate).format('DD/MM/YYYY') : null
    this.setState({ submitLoading: true })
    return actionSave(valuesForm, url, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Successfully saved')
        history.push('/agency/jobs')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    values.dateOpened = values.dateOpened ? moment(values.dateOpened).format('DD/MM/YYYY') : null
    values.targetDate = values.targetDate ? moment(values.targetDate).format('DD/MM/YYYY') : null
    this.setState({ submitLoading: true })
    return actionCreate(values, this.state.url, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/agency/jobs')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }
  
  render() {
    const { 
      getSkill,
      getClient, 
      getBenefit,
      getCountry, 
      getEducation, 
      getFieldOfStudy, 
      getJobIndustries, 
      getJobDestinations, 
    } = this.props;

    const { 
      valueForm,
      allowance, 
      clientName, 
      isFixSalary, 
      requirement, 
      loadingSelect,
      placementType, 
      submitLoading, 
      dataJobPosition, 
      isDisplaySalary, 
    } = this.state;

    if(getClient.loading || getJobIndustries.loading || getCountry.loading || getEducation.loading || getBenefit.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form 
          layout="vertical" 
          ref={this.formRef} 
          scrollToFirstError={true}
          onFinish={this.onFinish} 
          onValuesChange={(_, value) => {
            this.handleChangeValue(value)
          }}
        >
          <Row gutter={[16, 16]}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><SoundOutlined /> Job Posting</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/agency/jobs">Job Posting List</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title='Create a Job' 
                extra={[
                  <Button key="3" className="btn-save-primary" type="default" onClick={this.onCancel}>Cancel</Button>,
                  <Button key="2" className="btn-radius" type="primary" onClick={() => this.onSave(this.formRef.current)} loading={submitLoading} disabled={valueForm !== null ? false : true} ghost>Save as draft</Button>,
                  <Button key="1" className="btn-radius" type="primary" htmlType="submit" loading={submitLoading}>Submit and Propose</Button>,
                ]}
              />
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>

                  <Col span={24} style={{ marginBottom: 30 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Client Information</Typography.Text>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Client Name" 
                      name="clientId"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select an client"
                        optionFilterProp="children"
                        onChange={this.handleChangeClient}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getClient?.data.map(item => (
                            <Select.Option key={item.picName} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Contact Person Name">
                      <Typography.Text>{clientName ? clientName : 'N/A'}</Typography.Text>
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ marginBottom: 30 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Job Opening Information</Typography.Text>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Job Posting Title" 
                      name="title" 
                      initialValue={null}
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Title must be at least 3 characters long' },
                        { max: 100, message: 'Title is too long. Maximum is 100 characters' },
                        { pattern: new RegExp("^[a-zA-Z0-9/()-- ]+$"), message: 'Only dash (-), slash (/) and bracket ( () ) are allowed”' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Job Industry" 
                      name="jobIndustryId"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select an industry"
                        optionFilterProp="children"
                        onChange={this.handleChangeJobPosition}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getJobIndustries?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.title}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Job Type" 
                      name="jobType"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select placeholder='Select a type'>
                        <Select.Option value="FULL_TIME">Full Time</Select.Option>
                        <Select.Option value="PART_TIME">Part Time</Select.Option>
                        <Select.Option value="FREELANCE">Freelance</Select.Option>
                        <Select.Option value="INTERNSHIP">Internship</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Job Position" 
                      name="jobPositionId"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a position"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataJobPosition?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.title}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Placement Type" 
                      name="placementType" 
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select placeholder="Select a placement type" onChange={this.handlePlacementType}>
                        <Select.Option value="DOMESTIC">Domestic</Select.Option>
                        <Select.Option value="INTERNATIONAL">International</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Job Placement" 
                      name="jobDestinationId"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={false}
                        loading={loadingSelect}
                        placeholder="Select a location"
                        onBlur={this.onBlurJobDestinations}
                        onSearch={this.onSearchJobDestinations}
                        notFoundContent={loadingSelect ? <Spin size="small" /> : null}
                      >
                        {
                         getJobDestinations.data && getJobDestinations.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Is it possible to work from home (remote)?" 
                      name="isRemote"
                      initialValue={false} 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Experience Level" 
                      name="experienceLevel"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select placeholder='Select a level'>
                        <Select.Option value="ENTRY_LEVEL">Entry Level</Select.Option>
                        <Select.Option value="ASSOCIATE">Associate</Select.Option>
                        <Select.Option value="MID_SENIOR">Mid-Senior Level</Select.Option>
                        <Select.Option value="DIRECTOR">Director</Select.Option>
                        <Select.Option value="EXECUTIVE">Executive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Number of Position" 
                      name="numberOfPosition"
                      validateFirst
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { pattern: /^[0-9]*$/, message: 'Number only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Date Opened"
                      name="dateOpened"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker 
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }} 
                        disabledDate={this.disabledDate} 
                        onChange={this.onChangeStartDate}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Display salary information in job listings?" 
                      name="isDisplaySalary" 
                      initialValue={true}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Radio.Group onChange={(e) => this.handleDisplaySalary(e.target.value)}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Close Application Date"
                      name="targetDate"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker 
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }} 
                        disabledDate={this.disabledEndDate} 
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Payment Type" 
                      name="paymentType"
                      initialValue={null}
                      rules={[
                        { required: isDisplaySalary, message: 'This is a required field' }
                      ]}
                    >
                      <Select placeholder='Select a payment type'>
                        <Select.Option value="DAILY">Per Day</Select.Option>
                        <Select.Option value="WEEKLY">Per Week</Select.Option>
                        <Select.Option value="MONTHLY">Per Month</Select.Option>
                        <Select.Option value="YEARLY">Per Year</Select.Option>
                        <Select.Option value="PROJECT">Per Project</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Salary Type" 
                      name="isFixSalary" 
                      initialValue={true}
                      rules={[
                        { required: isDisplaySalary, message: 'This is a required field' }
                      ]}
                    >
                      <Radio.Group onChange={(e) => this.handleChangeFixSalary(e.target.value)}>
                        <Radio value={true}>Fix Salary</Radio>
                        <Radio value={false}>Range</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Payroll Currency" 
                      name="currencyId"
                      initialValue={null}
                      rules={[
                        { required: isDisplaySalary, message: 'This is a required field' }
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a currency"
                        optionFilterProp="children"
                      >
                        {
                          getCountry?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.currencyCode} - {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  {
                    isFixSalary ?
                    <Col span={12}>
                      <Form.Item 
                        label="Salary" 
                        name="salary" 
                        validateFirst
                        initialValue={null}
                        rules={[
                          { required: isDisplaySalary, message: 'This is a required field' },
                        ]}
                      >
                        <InputNumber
                          maxLength={12}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col>
                    :
                    <React.Fragment>
                      <Col span={6}>
                        <Form.Item 
                          label="Min Salary" 
                          name="salary" 
                          initialValue={null}
                          rules={[
                            { required: isDisplaySalary, message: 'This is a required field' },
                          ]}
                        >
                          <InputNumber
                            maxLength={12}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item 
                          label="Max Salary" 
                          name="maxSalary" 
                          initialValue={null}
                          rules={[
                            { required: isDisplaySalary, message: 'This is a required field' },
                          ]}
                        >
                          <InputNumber
                            maxLength={12}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Col>
                    </React.Fragment>
                  }

                  <Col span={24} style={{ marginBottom: 30 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Description Information</Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Job Description" 
                      name="jobDescription" 
                      initialValue={null}
                      tooltip={{ 
                        title: 'Input job roles and responsibilities', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { max: 8000, message: '8,000 characters only' },
                      ]}
                    >
                      <ReactQuill placeholder="Job Description" />
                    </Form.Item>
                  </Col>

                  {     
                    placementType === 'DOMESTIC' ?
                    <React.Fragment>
                      <Col span={24}>
                        <Form.Item 
                          label="Requirements" 
                          name="jobRequirement" 
                          initialValue={null}
                          tooltip={{ 
                            title: 'Input required and preferred job skills, and any specific candidate qualifications', 
                            icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                          }}
                          rules={[
                            { max: 8000, message: '8,000 characters only' },
                          ]}
                        >
                          <ReactQuill placeholder="Requirements" />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item 
                          label="Benefits" 
                          name="benefit" 
                          initialValue={null}
                          tooltip={{ 
                            title: 'Input company benefits details', 
                            icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                          }}
                          rules={[
                            { max: 8000, message: '8,000 characters only' },
                          ]}
                        >
                          <ReactQuill placeholder="Benefits" />
                        </Form.Item>
                      </Col>
                    </React.Fragment>
                    :
                    null
                  }

                  {
                    placementType === 'INTERNATIONAL' ?
                    <React.Fragment>
                      <Col span={24} style={{ marginBottom: 30 }}>
                        <Typography.Text style={{ fontSize: 18 }}>Terms and Conditions</Typography.Text>
                      </Col>

                      <Col span={12}>
                        <Form.Item 
                          label="Duration of contract (years)" 
                          name="durationOfContract"
                          validateFirst
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                            { max: 2, message: '2 digits only'}, 
                            { pattern: /^[0-9]*$/, message: 'Number only' },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item 
                          label="Contract is extendable" 
                          name="isContractExtendable" 
                          initialValue={true}
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item 
                          label="Annual Leave (days)" 
                          name="annualLeave"
                          validateFirst
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                            { max: 3, message: '3 digits only'}, 
                            { pattern: /^[0-9]*$/, message: 'Number only' },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item 
                          label="Paid Annual Leave" 
                          name="isPaidAnnualLeave" 
                          initialValue={true}
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item 
                          label="Working Hours (per day)" 
                          name="workingHour"
                          validateFirst
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                            { max: 3, message: '3 digits only'}, 
                            { pattern: /^[0-9]*$/, message: 'Number only' },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item 
                          label="Working Days (per week)" 
                          name="workingDay"
                          validateFirst
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                            { max: 1, message: '1 digits only'}, 
                            { pattern: /^[0-9]*$/, message: 'Number only' },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item 
                          label="Probation Period (months)" 
                          name="probationPeriod"
                          validateFirst
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                            { max: 2, message: '2 digits only'}, 
                            { pattern: /^[0-9]*$/, message: 'Number only' },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item 
                          label="Working Overtime" 
                          name="isWorkingOvertime" 
                          initialValue={true}
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item 
                          label="Other Working System" 
                          name="otherWorkingSystem"
                          initialValue={null}
                          rules={[
                            { max: 200, message: '200 characters only' },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={24} style={{ marginBottom: 30 }}>
                        <Typography.Text style={{ fontSize: 18 }}>Benefits</Typography.Text>
                      </Col>

                      <Form.List name="listBenefit">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field, index) => (
                              <React.Fragment key={field.fieldKey}>
                                <Col span={7}>
                                  <Form.Item 
                                    {...field}
                                    name={[index, 'name']}
                                    initialValue={null}
                                    rules={[
                                      { required: true, message: 'This is a required field' },
                                    ]}
                                  >
                                    <Select placeholder="Select a Benefit" onChange={this.handleChangeBenefit}>
                                      {
                                        getBenefit.data && getBenefit.data.map((res, i) => {
                                          const act = this.state.benefit.includes(res.name);
                                          return (
                                            <React.Fragment key={i}>
                                              {
                                                !act && 
                                                <Select.Option key={i} value={res.name}>
                                                  {res.name}
                                                </Select.Option>
                                              }
                                            </React.Fragment>
                                          )
                                        })
                                      }
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item 
                                    {...field}
                                    noStyle
                                    name={[index, 'status']}
                                    fieldKey={[index, 'status']}
                                    initialValue={false}
                                    rules={[
                                      { required: true, message: 'This is a required field' },
                                    ]}
                                  >
                                    <Radio.Group onChange={(e) => this.handleChangeAllowance(e.target.value, index)}>
                                      <Radio value={false}>Provided, no amount</Radio>
                                      <Radio value={true}>Allowance</Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                                
                                <Col span={8}>
                                  <Form.Item 
                                    {...field}
                                    name={[index, 'allowance']}
                                    fieldKey={[index, 'allowance']}
                                    rules={[
                                      { required: allowance[index], message: 'This is a required field' },
                                    ]}
                                  >
                                    <InputNumber
                                      placeholder="Type an Allowance"
                                      maxLength={12}
                                      disabled={!allowance[index]}
                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    />
                                  </Form.Item>
                                </Col> 
                                <Col span={allowance ? 1 : 1}>
                                  <Form.Item>
                                    <MinusCircleOutlined onClick={() => {
                                      remove(field.name);
                                      return this.handleRemoveBenefit(index)
                                    }} />
                                  </Form.Item>
                                </Col>
                              </React.Fragment>
                            ))}
                            <Col span={24}>
                              <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  Add Benefit
                                </Button>
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Form.List>

                      <Col span={24} style={{ marginBottom: 30 }}>
                        <Typography.Text style={{ fontSize: 18 }}>Candidate Requirements</Typography.Text>
                      </Col>

                      <Col span={12}>
                        <Form.Item 
                          label="Gender" 
                          name="gender" 
                          initialValue="BOTH"
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value="BOTH">Male and Female</Radio>
                            <Radio value="MALE">Male</Radio>
                            <Radio value="FEMALE">Female</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item 
                          label="Start Age" 
                          name="ageStart"
                          validateFirst
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                            { max: 2, message: '2 digits only'}, 
                            { pattern: /^[0-9]*$/, message: 'Number only' },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item 
                          label="End Age" 
                          name="ageEnd"
                          validateFirst
                          dependencies={['ageStart']}
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                            { max: 2, message: '2 digits only'}, 
                            { pattern: /^[0-9]*$/, message: 'Number only' },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('ageStart') <= value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error('You must enter above the age start'));
                              },
                            })              
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item 
                          label="Minimum Education Level" 
                          name="educationLevelId"
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select an education level"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {
                              getEducation?.data.map(item => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item 
                          label="Fields of Study" 
                          name="fieldOfStudy"
                          validateFirst
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                            { max: 100, message: '100 characters only' }
                          ]}
                        >
                          <AutoComplete
                            style={{ width: '100%' }}
                            placeholder="Select a field of study"
                            onFocus={this.onFocusFieldOfStudy}
                            onSearch={this.onSearchFieldOfStudy}
                            notFoundContent={getFieldOfStudy.loading ? <Spin size="small" /> : null}
                            filterOption={false}
                          >
                            {
                              getFieldOfStudy.data && getFieldOfStudy.data.map((item, i) => (
                                <AutoComplete.Option key={i} value={item.name}>
                                  {item.name}
                                </AutoComplete.Option>
                              ))
                            }
                          </AutoComplete>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item 
                          label="Skills" 
                          name="skill"
                          initialValue={[]}
                          getValueFromEvent={value => value.slice(0, 5)}
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <Select
                            mode="tags"
                            showSearch
                            filterOption={false}
                            loading={loadingSelect}
                            onBlur={this.onBlurSkills}
                            onSearch={this.onSearchSkills}
                            notFoundContent={loadingSelect ? <Spin size="small" /> : null}
                          >
                            {
                              getSkill.data && getSkill.data.map(item => (
                                <Select.Option key={item.id} value={item.name}>
                                  {item.name}
                                </Select.Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item 
                          label="Other Requirements" 
                          name="otherRequirements"
                          initialValue={[]}
                        >
                          <Select mode="multiple" onChange={this.handleChangeRequirement}>
                            <Select.Option value="minExperience">Minimum Experience</Select.Option>
                            <Select.Option value="certification">Certification</Select.Option>
                            <Select.Option value="minHeight">Minimum Body Height (cm)</Select.Option>
                            <Select.Option value="minWeight">Minimum Body Weight (kg)</Select.Option>
                            <Select.Option value="notes">Notes</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      {
                        requirement && requirement.map((item, i) => (
                          <React.Fragment key={i}>
                            {
                              item.value === 'minExperience' ?
                              <Col span={12}>
                                <Form.Item 
                                  label="Minimum Experience (years)" 
                                  name="minExperience"
                                  validateFirst
                                  initialValue={null}
                                  rules={[
                                    { required: true, message: 'This is a required field' },
                                    { max: 2, message: '2 digits only'}, 
                                    { pattern: /^[0-9]*$/, message: 'Number only' },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              :
                              null
                            }
                            {
                              item.value === 'certification' ?
                              <Col span={12}>
                                <Form.Item 
                                  label="Certification" 
                                  name="certification"
                                  validateFirst
                                  initialValue={null}
                                  rules={[
                                    { required: true, message: 'This is a required field' },
                                    { max: 50, message: '50 characters only' },               
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              :
                              null
                            }
                            {
                              item.value === 'minHeight' ?
                              <Col span={12}>
                                <Form.Item 
                                  label="Minimum Body Height (cm)" 
                                  name="minHeight"
                                  validateFirst
                                  initialValue={null}
                                  rules={[
                                    { required: true, message: 'This is a required field' },
                                    { pattern: /^[0-9]*$/, message: 'Number only' },
                                    { min: 3, message: '3 digits only' },
                                    { max: 3, message: '3 digits only' }
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              :
                              null
                            }
                            {
                              item.value === 'minWeight' ?
                              <Col span={12}>
                                <Form.Item 
                                  label="Minimum Body Weight (kg)" 
                                  name="minWeight"
                                  validateFirst
                                  initialValue={null}
                                  rules={[
                                    { required: true, message: 'This is a required field' },
                                    { pattern: /^[0-9]*$/, message: 'Number only' },
                                    { min: 2, message: '2 digits only' },
                                    { max: 2, message: '2 digits only' },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              :
                              null
                            }
                            {
                              item.value === 'notes' ?
                              <Col span={24}>
                                <Form.Item 
                                  label="Notes" 
                                  name="notes"
                                  initialValue={null}
                                  rules={[
                                    { required: true, message: 'This is a required field' },
                                    { max: 100, message: '100 characters only' },               
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              :
                              null
                            }
                          </React.Fragment>  
                        ))
                      }
                    </React.Fragment>
                    :
                    null
                  }

                  <Divider />

                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Space>
                        <Button className="btn-save-primary" type="default" onClick={this.onCancel}>Cancel</Button>
                        <Button className="btn-radius" type="primary" onClick={() => this.onSave(this.formRef.current)} loading={submitLoading} disabled={valueForm !== null ? false : true} ghost>Save as draft</Button>
                        <Button className="btn-radius" type="primary" htmlType="submit" loading={submitLoading}>Submit and Propose</Button>
                      </Space>
                    </Form.Item>
                  </Col>
                  
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { 
      unmountGetClient,
      unmountGetJobIndustries, 
      unmountGetJobDestinantions, 
      unmountGetCountry, 
      unmountGetEducation, 
      unmountGetFieldOfStudy, 
      unmountGetSkill, 
      unmountGetBenefit 
    } = this.props;
    unmountGetClient()
    unmountGetJobIndustries()
    unmountGetJobDestinantions()
    unmountGetCountry()
    unmountGetEducation()
    unmountGetFieldOfStudy()
    unmountGetSkill()
    unmountGetBenefit()
  }
}

const mapStateToProps = (state) => ({
  getSkill            : state.skills.list,
  getClient           : state.clients.master,
  getBenefit          : state.benefits.master,
  getCountry          : state.countries.master,
  getFieldOfStudy     : state.fieldOfStudy.list,
  getJobIndustries    : state.jobIndustries.master,
  getJobDestinations  : state.jobDestinations.list,
  getEducation        : state.educationLevel.master,
})

const mapDispatchToProps = {
  actionCreate                : createJob,
  actionSave                  : saveJob,
  actionGetClient             : masterClients,
  actionGetJobIndustries      : masterJobIndustries,
  actionGetJobPosition        : positionByIndustry,
  actionGetCountry            : masterCountries,
  actionGetJobDestinations    : listJobDestinations,
  actionGetBenefit            : masterBenefit,
  actionGetEducation          : masterEducationLevel,
  actionGetFieldOfStudy       : listFieldOfStudy,
  actionGetSkill              : listSkills,
  unmountGetClient            : unmountMasterClients,
  unmountGetJobIndustries     : unmountMasterJobIndustries,
  unmountGetJobDestinantions  : unmountListJobDestinations,
  unmountGetBenefit           : unmountMasterBenefit,
  unmountGetCountry           : unmountMasterCountries,
  unmountGetEducation         : unmountMasterEducationLevel,
  unmountGetFieldOfStudy      : unmountListFieldOfStudy,
  unmountGetSkill             : unmountListSkill
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAgencyJobs)
