import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createCountries } from '../../../../../redux/actions/master/countries/countriesAction';
import { InfoCircleOutlined, DatabaseOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Form, Row, Input, Button, Space, Select, Divider, PageHeader, message, Card, Breadcrumb, Image, Upload } from 'antd';

class CreateMasterCountry extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null,
    }
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    if(this.state.editAvatar){
      values.flagNameFile = this.state.avatar
    }
    this.setState({ submitLoading: true })
    return actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/master/countries')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading, editAvatar, onPreviewAvatar } = this.state;
    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} layout="vertical">
          <Row gutter={[16, 16]}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/countries">
                    Master Data List Countries
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Countries</Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader className="site-page-header" title="Create a Country" />
            </Col>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      label="Country Name" 
                      name="name" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 60, message: 'Country Name is too long. Maximum is 60 characters' },
                        { pattern: new RegExp(/^([^0-9]*)$/), message: 'Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Will it be displayed in the candidate's side?" 
                      name="isDisplay" 
                      initialValue={false}
                      tooltip={{ 
                        title: 'If you choose yes, this country will be displayed in the Job Preferences option', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Yes</Select.Option>
                        <Select.Option value={false}>No</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled" 
                      initialValue={true}
                      tooltip={{ 
                        title: 'Inactive means this country isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option key="1" value={true}>Active</Select.Option>
                        <Select.Option key="2" value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Alpha 2 Code" 
                      name="alpha2Code"
                      validateFirst
                      initialValue=''
                      getValueFromEvent={ e => e.target.value.toUpperCase().trim()}
                      rules={[
                        { max: 2, message: '2 characters only' },
                        { min: 2, message: 'Alpha 2 code must be at least 2 characters long' },
                        { pattern: new RegExp("^[a-zA-Z]+$"), message: 'Please enter on alphabets only' },
                      ]}
                    >
                      <Input style={{ textTransform: 'uppercase' }}  />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Alpha 3 Code"
                      name="alpha3Code"
                      validateFirst
                      initialValue=''
                      getValueFromEvent={ e => e.target.value.toUpperCase().trim()}
                      rules={[
                        { max: 3, message: '3 characters only' },
                        { min: 3, message: 'Alpha 3 code must be at least 2 characters long' },
                        { pattern: new RegExp("^[a-zA-Z]+$"), message: 'Please enter on alphabets only' },
                      ]}
                    >
                      <Input style={{ textTransform: 'uppercase' }} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Nationality" 
                      name="nationality"
                      validateFirst
                      initialValue=''
                      rules={[
                        { max: 50, message: '50 characters only' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Please enter on alphabets only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Calling Code" 
                      name="callingCode"
                      validateFirst
                      initialValue=''
                      rules={[
                        { max: 5, message: '5 characters only' },
                        { pattern: new RegExp("^[0-9]+$"), message: 'Please enter on numerics only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Currency Name" 
                      name="currencyName"
                      validateFirst
                      initialValue=''
                      rules={[
                        { max: 50, message: '50 characters only' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Please enter on alphabets only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Currency Code" 
                      name="currencyCode"
                      validateFirst
                      initialValue=''
                      getValueFromEvent={ e => e.target.value.toUpperCase().trim()}
                      rules={[
                        { max: 3, message: '3 characters only' },
                        { pattern: new RegExp("^[a-zA-Z]+$"), message: 'Please enter on alphabets only' },
                      ]}
                    >
                      <Input style={{ textTransform: 'uppercase' }} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Description" 
                      name="description"
                      initialValue=''
                      rules={[
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Flag">
                      {
                        editAvatar ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                        </Form.Item>
                        :
                        null
                      }
                      <Space>
                        <Upload {...this.handleUpload()}>
                          <Button icon={<UploadOutlined />}>{editAvatar ? 'Change File' : 'Upload a File'}</Button>
                        </Upload>
                        {
                          editAvatar ?
                            <Button icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                          : null
                        }
                      </Space>
                    </Form.Item>
                  </Col>

                  <Divider />
                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Space>
                      <Button className="btn-save-primary" type="default"onClick={() => window.history.back()}>Cancel</Button>
                      <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
  actionCreate: createCountries,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMasterCountry)
