import React, { Component } from 'react';
import About from './About';
import Location from './Location';
import SliderPhoto from './SliderPhoto';
import { connect } from 'react-redux';
import { Row, Col, PageHeader } from 'antd';
import { cityByProvince } from '../../../redux/actions/master/cities/citiesAction';
import { provinceByCountry } from '../../../redux/actions/master/provinces/provincesAction';
import { masterCountries, unmountMasterCountries } from '../../../redux/actions/master/countries/countriesAction';
import { masterJobIndustries, unmountMasterJobIndustries } from '../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { getAbout, getLocation, sliderPhoto, unmountAbout, unmountLocation, unmountSliderPhoto, updateAbout, updateLocation, updateSliderPhoto } from '../../../redux/actions/companyProfile/companyProfileAction';

class CompanyProfileEmployer extends Component {
  render() {
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <PageHeader className="site-page-header" title="Company Profile" />
          </Col>
          <Col span={24}>
            <SliderPhoto {...this.props} />
          </Col>
          <Col span={24}>
            <About {...this.props} />
          </Col>
          <Col span={24}>
            <Location {...this.props} />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetSliderPhoto, unmountGetAbout, unmountGetLocation, unmountGetJobIndustry, unmountGetCountry } = this.props;
    unmountGetSliderPhoto()
    unmountGetAbout()
    unmountGetLocation()
    unmountGetJobIndustry()
    unmountGetCountry()
  }
}

const mapStateToProps = (state) => ({
  getSliderPhoto    : state.companyProfile.sliderPhoto,
  getDetailAbout    : state.companyProfile.about,
  getDetailLocation : state.companyProfile.location,      
  getJobIndustry    : state.jobIndustries.master,
  getCountry        : state.countries.master
})

const mapDispatchToProps = {
  actionUpdateSlider    : updateSliderPhoto,
  actionUpdateAbout     : updateAbout,
  actionUpdateLocation  : updateLocation,
  actionGetSliderPhoto  : sliderPhoto,
  actionGetAbout        : getAbout,
  actionGetLocation     : getLocation,
  actionGetJobIndustry  : masterJobIndustries,
  actionGetCountry      : masterCountries,
  actionGetProvince     : provinceByCountry,
  actionGetCity         : cityByProvince,
  unmountGetSliderPhoto : unmountSliderPhoto,
  unmountGetAbout       : unmountAbout,
  unmountGetLocation    : unmountLocation,
  unmountGetJobIndustry : unmountMasterJobIndustries,
  unmountGetCountry     : unmountMasterCountries,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileEmployer)
