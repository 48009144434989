import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { BankOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { checkEmail, checkPhone } from '../../../../redux/actions/check/checkAction';
import { cityByProvince } from '../../../../redux/actions/master/cities/citiesAction';
import { provinceByCountry } from '../../../../redux/actions/master/provinces/provincesAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/master/countries/countriesAction';
import { masterJobIndustries, unmountMasterJobIndustries } from '../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { createEmployer, masterEmployers, unmountMasterEmployers } from '../../../../redux/actions/employers/employersAction';
import { Card, Row, Col, PageHeader, Form, Input, Select, Upload, Button, Divider, Space, Breadcrumb, Image, message } from 'antd';

class CreateEmployer extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      submitLoading: false,
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null,
      callingCode: "62",
      dataProvince: [],
      dataCity: []
      }
    }

  componentDidMount()  {
    const { 
      actionGetJobIndustries, 
      actionGetCountry, 
      actionGetEmployer, 
      actionGetProvince 
    } = this.props;
    actionGetProvince(99, (response) => {
      this.setState({ dataProvince: response.data })
    })
    actionGetJobIndustries()
    actionGetCountry()
    actionGetEmployer()
  }

  checkEmail = (value) => {
    const { actionCheckEmail } = this.props;
    return actionCheckEmail(value, (response) => {
      if(response){
        message.error('Email already exist')
        this.formRef.current.setFieldsValue({
          email: null,
        });
      }
    })
  }

  checkPhone = (value) => {
    const { callingCode } = this.state;
    const { actionCheckPhone } = this.props;
    const valuePhone = `${callingCode}${value.replace(/^0+/, '')}`
    this.formRef.current.setFieldsValue({
      mobilePhone: value.replace(/^0+/, ''),
    });
    return actionCheckPhone(valuePhone.replace(/^0+/, ''), (response) => {
      if(response){
        message.error('Mobile phone already exist')
        this.formRef.current.setFieldsValue({
          mobilePhone: null,
        });
      }
    })
  }

  handleChangeCallingCode = (_, index) => {
    this.setState({ callingCode: index.children.replace(/^[+]+/, '') })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    });
  }

  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
      cityId: null,
    });
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null
    });
    if(value !== undefined){
      return actionGetCity(value, (response) => {
        this.setState({ dataCity: response.data })
      })
    }else {
      this.setState({ dataCity: [] })
    }
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  onFinish = (values) => {
    const { callingCode, avatar } = this.state;
    const { actionCreate, history } = this.props;
    values.mobilePhone = `${callingCode}${values.mobilePhone.replace(/^0+/, '')}`
    values.jobIndustryId = values.jobIndustryId ? values.jobIndustryId : ''
    values.provinceId = values.provinceId ? values.provinceId : ''
    values.cityId = values.cityId ? values.cityId : ''
    if(this.state.editAvatar){
      values.logoFile = avatar
    }
    this.setState({ submitLoading: true })
    return actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/employers')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading, editAvatar, onPreviewAvatar, dataProvince, dataCity } = this.state;
    const { getJobIndustries, getCountry, getEmployer } = this.props;

    if(getJobIndustries.loading || getCountry.loading || getEmployer.loading){
      return <Loading />
    }
    
    const mobileNumber = (
      <Form.Item 
        noStyle
        name="callingCodeId" 
        initialValue={99} 
        rules={[ {required: true, message: 'Please input your calling code'} ]}
      >
        <Select 
          showSearch
          className="prefix-selector"
          optionFilterProp="children"
          onChange={this.handleChangeCallingCode}
        >
          {
            getCountry?.data.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {`+${item.callingCode}`}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>
    );

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>

          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BankOutlined /> Employers</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/employers">Employers List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Create Employer</Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          {/* Page Header */}
          <Col span={24}>
            <PageHeader title="Create an Employer" className="site-page-header" />
          </Col>

          {/* Form */}
          <Col span={24}>
            <Card>
              <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      label="Employer Name" 
                      name="name"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: 'Employer Name is too long. Maximum is 50 characters' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                      ]}
                      >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Employer Email" 
                      name="email" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { type: 'email', message: 'The input is not valid E-mail' }, 
                      ]}
                    >
                      <Input type="email" onBlur={(e) => this.checkEmail(e.target.value)} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person" 
                      name="picName"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },               
                        { max: 50, message: 'Contact Person is too long. Maximum is 50 characters' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed!' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person Mobile Number" 
                      name="mobilePhone"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 4, message: 'Your mobile number is invalid' },
                        { max: 13, message: 'Your mobile number is invalid' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input addonBefore={mobileNumber} onBlur={(e) => this.checkPhone(e.target.value)} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Employer Type" 
                      name="employersTypeId" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getEmployer?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Industry" 
                      name="jobIndustryId"
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select an Industry"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getJobIndustries?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.title}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person Email" 
                      name="picEmail" 
                      initialValue=''
                      rules={[
                        { type: 'email', message: 'Your email address is invalid' }
                      ]}
                    >
                      <Input type="email" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Specialties (niche or specific industry of the employer)" 
                      name="specialties" 
                      initialValue=''
                    >
                      <Input placeholder="e.g. Recruiting, HR Software" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Office Phone Number" 
                      name="officePhone" 
                      validateFirst
                      initialValue=''
                      rules={[
                        { min: 4, message: 'Your mobile number is invalid' },
                        { max: 13, message: 'Your mobile number is invalid' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },  
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Country" name="countryId" initialValue={99}>
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a country"
                        optionFilterProp="children"
                        onChange={this.handleChangeCountry}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getCountry?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Province" name="provinceId">
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a province"
                        onChange={this.handleChangeProvince}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataProvince.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="City" name="cityId">
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a city"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataCity.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Street Address" 
                      name="address"
                      initialValue=''
                      rules={[
                        { max: 100, message: 'Address is too long. Maximum is 100 characters' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Postal Code" 
                      name="zipCode"
                      validateFirst
                      initialValue=''
                      rules={[
                        { max: 11, message: 'Maximum postal code is 11 characters' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Company Size" name="totalEmployee" initialValue=''>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Select.Option value="1 - 20 employees">1 - 20 employees</Select.Option>
                        <Select.Option value="21 - 100 employees">21 - 100 employees</Select.Option>
                        <Select.Option value="100 - 250 employees">100 - 250 employees</Select.Option>
                        <Select.Option value="250 - 500 employees">250 - 500 employees</Select.Option>
                        <Select.Option value="500 - 1000 employees">500 - 1000 employees</Select.Option>
                        <Select.Option value="> 1000 employees">{`> 1000 employees`}</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Website" 
                      name="website"
                      validateFirst
                      initialValue=''
                      rules={[
                        { max: 100, message: 'Maximum is 100 characters' },
                        { pattern: new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi), message: 'Invalid website url' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="About" 
                      name="about"
                      initialValue=''
                      rules={[
                        { max: 2000, message: 'Maximum is 2000 characters' },
                      ]}
                    >
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Logo">
                      {
                        editAvatar ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                        </Form.Item>
                        :
                        null
                      }
                      <Space>
                        <Upload {...this.handleUpload()}>
                          <Button icon={<UploadOutlined />}>{editAvatar ? 'Change File' : 'Upload a File'}</Button>
                        </Upload>
                        {
                          editAvatar ?
                            <Button icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                          : null
                        }
                      </Space>
                    </Form.Item>
                  </Col>

                  <Divider />

                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Space>
                        <Button className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>
                        <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                      </Space>
                    </Form.Item>
                  </Col>

                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetJobIndustries, unmountGetCountry, unmountGetEmployer } = this.props;
    unmountGetEmployer()
    unmountGetCountry()
    unmountGetJobIndustries()
  }
}

const mapStateToProps = (state) => ({
  getJobIndustries  : state.jobIndustries.master,
  getCountry        : state.countries.master,
  getEmployer       : state.employers.master
})

const mapDispatchToProps = {
  actionCreate            : createEmployer,
  actionCheckEmail        : checkEmail,
  actionCheckPhone        : checkPhone,
  actionGetJobIndustries  : masterJobIndustries,
  actionGetCountry        : masterCountries,
  actionGetProvince       : provinceByCountry,
  actionGetCity           : cityByProvince,
  actionGetEmployer       : masterEmployers,
  unmountGetCountry       : unmountMasterCountries,
  unmountGetEmployer      : unmountMasterEmployers,
  unmountGetJobIndustries : unmountMasterJobIndustries
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmployer)
