import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import notificationReducer from './notification/notificationReducer';
import CountNotificationReducer from './notification/countNotificationReducer';
import applicantSummaryReducer from './dashboard/applicantSummaryReducer';
import pipelineHiringReducer from './dashboard/pipelineHiringReducer';
import jobOverviewReducer from './dashboard/jobOverviewReducer';
import jobOpeningStatusReducer from './dashboard/jobOpeningStatusReducer';
import candidateReducer from './candidate/candidateReducer';
import candidateDetailReducer from './candidate/candidateDetailReducer';
import jobsReducer from './jobs/jobsReducer';
import jobsDetailReducer from './jobs/jobsDetailReducer';
import jobsDetailActiveApplicantReducer from './jobs/jobsDetailActiveApplicant';
import jobsListApplicantReducer from './jobs/jobsListApplicantReducer';
import jobsListRejectedReducer from './jobs/jobsListRejectedReducer';
import jobsAppliedReducer from './jobs/jobsAppliedReducer';
import jobsDetailInterviewReducer from './jobs/jobsDetailInterview';
import agenciesReducer from './agencies/agenciesReducer';
import agenciesDetailReducer from './agencies/agenciesDetailReducer';
import masterAgenciesReducer from './agencies/masterAgeciesReducer';
import employersReducer from './employers/employersReducer';
import employersDetailReducer from './employers/employersDetailReducer';
import masterEmployersReducer from './employers/masterEmployersReducer';
import partnersReducer from './partners/partnersReducer';
import partnersDetailReducer from './partners/partnersDetailReducer';
import masterPartnersReducer from './partners/masterPartnersReducer';
import countriesReducer from './master/countries/countriesReducer';
import countriesDetailReducer from './master/countries/countriesDetailReducer';
import masterCountriesReducer from './master/countries/masterCountriesReducer';
import provincesReducer from './master/provinces/provincesReducer';
import provincesDetailReducer from './master/provinces/provincesDetailReducer';
import masterProvincesReducer from './master/provinces/masterProvincesReducer';
import citiesReducer from './master/cities/citiesReducer';
import citiesDetailReducer from './master/cities/citiesDetailReducer';
import masterCitiesReducer from './master/cities/masterCitiesReducer';
import subDistrictReducer from './master/subDistrict/subDistrictReducer';
import subDistrictDetailReducer from './master/subDistrict/subDistrictDetailReducer';
import masterSubDistrictReducer from './master/subDistrict/masterSubDistrictReducer';
import villageReducer from './master/villages/villageReducer';
import villageDetailReducer from './master/villages/villageDetailReducer';
import masterVillageReducer from './master/villages/masterVillageReducer';
import jobIndustriesReducer from './master/jobIndustries/jobIndustriesReducer';
import jobIndustriesDetailReducer from './master/jobIndustries/jobIndustriesDetailReducer';
import masterJobIndustriesReducer from './master/jobIndustries/masterJobIndustriesReducer';
import jobPositionsReducer from './master/jobPositions/jobPositionsReducer';
import jobPositionsDetailReducer from './master/jobPositions/jobPositionsDetailReducer';
import masterJobPositionsReducer from './master/jobPositions/masterJobPositionsReducer';
import jobPositionByIndustryReducer from './master/jobPositions/jobPositionByIndustryReducer';
import jobDestinationsReducer from './master/jobDestinations/jobDestinationsReducer';
import jobDestinationsDetailReducer from './master/jobDestinations/jobDestinationsDetailReducer';
import jobDestinationGroupReducer from './master/jobDestinations/jobDestinationGroupReducer';
import masterJobDestinationsReducer from './master/jobDestinations/masterJobDestinationsReducer';
import educationLevelReducer from './master/educationLevel/educationLevelReducer';
import educationLevelDetailReducer from './master/educationLevel/educationLevelDetailReducer';
import masterEducationLevelReducer from './master/educationLevel/masterEducationLevelReducer';
import institutionsReducer from './master/institutions/institutionsReducer';
import institutionsDetailReducer from './master/institutions/institutionsDetailReducer';
import masterInstitutionsReducer from './master/institutions/masterInstitutionsReducer';
import fieldOfStudyReducer from './master/fieldOfStudy/fieldOfStudyReducer';
import fieldOfStudyDetailReducer from './master/fieldOfStudy/fieldOfStudyDetailReducer';
import masterFieldOfStudyReducer from './master/fieldOfStudy/masterFieldOfStudyReducer';
import skillReducer from './master/skills/skillReducer';
import skillDetailReducer from './master/skills/skillDetailReducer';
import masterSkillReducer from './master/skills/masterSkillReducer';
import masterBenefitReducer from './master/benefits/masterBenefitReducer';
import clientReducer from './client/clientReducer';
import clientDetailReducer from './client/clientDetailReducer';
import masterClientReducer from './client/masterClientReducer';
import sliderPhotoReducer from './companyProfile/sliderPhotoReducer';
import aboutReducer from './companyProfile/aboutReducer';
import locationReducer from './companyProfile/locationReducer';
import reportApplicantSummary from './report/reportApplicantSummaryReducer';
import reportHiringPipeline from './report/reportHiringPipelineReducer';
import reportJobOverview from './report/reportJobOverviewReducer';
import emailReducer from './settings/emailReducer';
import contactReducer from './settings/contactReducer';

export default combineReducers({
  authReducer,
  notification: combineReducers({
    list  : notificationReducer,
    count : CountNotificationReducer
  }),
  dashboard: combineReducers({
    applicantSummary  : applicantSummaryReducer,
    pipelineHiring    : pipelineHiringReducer,
    jobOverview       : jobOverviewReducer,
    jobOpeningStatus  : jobOpeningStatusReducer
  }),
  candidate: combineReducers({
    list    : candidateReducer,
    detail  : candidateDetailReducer
  }),
  jobs: combineReducers({
    list            : jobsReducer,
    detail          : jobsDetailReducer,
    activeApplicant : jobsDetailActiveApplicantReducer,
    applied         : jobsAppliedReducer,
    detailInterview : jobsDetailInterviewReducer,
    listApplicant   : jobsListApplicantReducer,
    listRejected    : jobsListRejectedReducer  
  }),
  agencies: combineReducers({
    list    : agenciesReducer,
    detail  : agenciesDetailReducer,
    master  : masterAgenciesReducer
  }),
  employers: combineReducers({
    list    : employersReducer,
    detail  : employersDetailReducer,
    master  : masterEmployersReducer
  }),
  partners: combineReducers({
    list    : partnersReducer,
    detail  : partnersDetailReducer,
    master  : masterPartnersReducer
  }),
  countries: combineReducers({
    list    : countriesReducer,
    detail  : countriesDetailReducer,
    master  : masterCountriesReducer
  }),
  provinces: combineReducers({
    list    : provincesReducer,
    detail  : provincesDetailReducer,
    master  : masterProvincesReducer
  }),
  cities: combineReducers({
    list    : citiesReducer,
    detail  : citiesDetailReducer,
    master  : masterCitiesReducer
  }),
  subDistricts: combineReducers({
    list    : subDistrictReducer,
    detail  : subDistrictDetailReducer,
    master  : masterSubDistrictReducer
  }),
  villages: combineReducers({
    list    : villageReducer,
    detail  : villageDetailReducer,
    master  : masterVillageReducer
  }),
  jobIndustries: combineReducers({
    list    : jobIndustriesReducer,
    detail  : jobIndustriesDetailReducer,
    master  : masterJobIndustriesReducer
  }),
  jobPositions: combineReducers({
    list            : jobPositionsReducer,
    detail          : jobPositionsDetailReducer,
    master          : masterJobPositionsReducer,
    listByIndustry  : jobPositionByIndustryReducer,
  }),
  jobDestinations: combineReducers({
    list    : jobDestinationsReducer,
    detail  : jobDestinationsDetailReducer,
    group   : jobDestinationGroupReducer,
    master  : masterJobDestinationsReducer,
  }),
  educationLevel: combineReducers({
    list    : educationLevelReducer,
    detail  : educationLevelDetailReducer,
    master  : masterEducationLevelReducer
  }),
  institutions: combineReducers({
    list    : institutionsReducer,
    detail  : institutionsDetailReducer,
    master  : masterInstitutionsReducer
  }),
  fieldOfStudy: combineReducers({
    list    : fieldOfStudyReducer,
    detail  : fieldOfStudyDetailReducer,
    master  : masterFieldOfStudyReducer
  }),
  skills: combineReducers({
    list    : skillReducer,
    detail  : skillDetailReducer,
    master  : masterSkillReducer
  }),
  benefits: combineReducers({
    master  : masterBenefitReducer
  }),
  clients: combineReducers({
    list    : clientReducer,
    detail  : clientDetailReducer,
    master  : masterClientReducer
  }),
  companyProfile: combineReducers({
    sliderPhoto : sliderPhotoReducer,
    about       : aboutReducer,
    location    : locationReducer,
  }),
  report: combineReducers({
    applicantSummary  : reportApplicantSummary,
    hiringPipeline    : reportHiringPipeline,
    jobOverview       : reportJobOverview
  }),
  settings: combineReducers({
    email       : emailReducer,
    contact     : contactReducer,
  }),
})