import React, { Component } from 'react';
import { Form, Row, Col, Input, Select, Spin, DatePicker, Typography, Checkbox } from 'antd';
const { Text } = Typography;

export default class AddWorkExperience extends Component {
  render() {
    const { 
      formRef, 
      getCity,
      isDate,
      current, 
      onSubmitAdd, 
      onFocusCity, 
      onSearchCity, 
      handleChecked,
      onChangeEndDate,
      onChangeStartDate,
    } = this.props;

    return (
      <React.Fragment>
        <Form ref={formRef} onFinish={onSubmitAdd} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="Title"
                name="title"
                validateFirst
                initialValue={null}
                rules={[
                  { max: 50, message: '50 characters only' },
                  { required: true, message: 'This is a required field' },
                  { min: 5, message: 'Title must be at least 5 characters' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Employment Type" 
                name="employmentType" 
                initialValue={null}
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <Select>
                  <Select.Option key="1" value="FULL_TIME">Full Time</Select.Option>
                  <Select.Option key="2" value="PART_TIME">Part Time</Select.Option>
                  <Select.Option key="3" value="FREELANCE">Freelance</Select.Option>
                  <Select.Option key="4" value="INTERNSHIP">Internship</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Company"
                name="company"
                validateFirst
                initialValue={null}
                rules={[
                  { max: 50, message: '50 characters only' },
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="City" 
                name="cityId"
                initialValue={null}
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <Select
                  showSearch
                  filterOption={false}
                  placeholder="type a city"
                  onFocus={onFocusCity}
                  onSearch={onSearchCity}
                  loading={getCity.loading}
                  notFoundContent={getCity.loading ? <Spin size="small" /> : null}
                >
                  {
                    getCity.data && getCity.data.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}, {item.countryName}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                label="Start Date" 
                name="startDate"
                initialValue={null}
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <DatePicker 
                  picker="month" 
                  placeholder="YYYY-MM" 
                  style={{ width: '100%' }}
                  onChange={onChangeStartDate} 
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                label="End Date" 
                name="endDate"
                initialValue={null}
                validateStatus={isDate && !current ? "error" : null}
                help={isDate && !current ? "Your end date can't be earlier than your start date" : null}
                rules={[
                  { required: !current, message: 'This is a required field' },
                ]}
              >
                {
                  current ?
                  <Text className="fs-12" type="secondary">Present</Text>
                  :
                  <DatePicker 
                    picker="month" 
                    placeholder="YYYY-MM" 
                    style={{ width: '100%' }}
                    onChange={onChangeEndDate} 
                  />
                }
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label=" " name="current">
                <Checkbox onChange={handleChecked} checked={current}>I'm currently working in this role</Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Job Description"
                name="jobDescription"
                validateFirst
                rules={[
                  { max: 200, message: '200 characters only' },
                ]}
              >
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
