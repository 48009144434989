import React from 'react';
import { Link } from 'react-router-dom';

export const columns = () => {
  return [
    {
      title: 'Posting Title',
      key: 'title',
      render: (record) => (
        <Link className="link-dashboard" to={`/employer/jobs/detail/${record.id}/information`}>
          {record.title}
        </Link>
      )
    },
    {
      title: 'Applied',
      key: 'applied',
      align: 'center',
      render: (record) => (
        <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/PT00`} style={{ color: '#1274db' }}>
          {record.applied}
        </Link>
      )
    },
    {
      title: 'Qualified',
      key: 'qualified',
      align: 'center',
      render: (record) => (
        <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/PT01/1`} style={{ color: '#9d7bd0' }}>
          {record.qualified}
        </Link>
      )
    },
    {
      title: 'Interview',
      key: 'interview',
      align: 'center',
      render: (record) => (
        <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/PT02/2/interview`} style={{ color: '#d78f13' }}>
          {record.interview}
        </Link>
      )
    },
    {
      title: 'Offered',
      key: 'offered',
      align: 'center',
      render: (record) => (
        <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/PT03/3`} style={{ color: '#0c999d' }}>
          {record.offered}
        </Link>
      )
    },
    {
      title: 'Hired',
      key: 'hired',
      align: 'center',
      render: (record) => (
        <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/PT04/4`} style={{ color: '#53ac2d' }}>
          {record.hired}
        </Link>
      )
    },
  ]
}