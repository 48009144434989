import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { columns } from './columns';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { HeaderTable } from '../../../components';
import { SoundOutlined, PlusOutlined } from '@ant-design/icons';
import { inactiveJob, listJobs, unmountListJobs } from '../../../redux/actions/jobs/jobsAction';
import { Row, Col, Breadcrumb, PageHeader, Button, Table, Pagination, Modal, Typography, message } from 'antd';
const { Text } = Typography;

class AgencyJobs extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selectedRowKeys: [],
      url: 'job_agency',
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        status: null, 
        startDate: null,
        endDate: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { actionGetData } = this.props;
    return actionGetData(this.state.meta, this.state.url)
  }

  onRegistered = value => {
    const { actionGetData } = this.props;
    const { meta, url } = this.state;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta, url)
  }

  onStatus = value => {
    const { meta, url } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.status = value
    return actionGetData(meta, url)
  }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta, url)
  }

  pagination = (page, perpage) => {
    const { meta, url } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta, url)
  }

  handleInactive = (value) => {
    const { meta, url } = this.state;
    const { actionInactive, actionGetData } = this.props;
    return actionInactive(value, url, (response) => {
     message.success('Successfully changed to inactive')
      return actionGetData(meta, url)
    })
  }

  onClickRow = (record) => {
    this.props.history.push(`/agency/jobs/detail/${record.id}/information`)
  }

  onClickEdit = (id) => {
    this.props.history.push(`/agency/jobs/edit/${id}`)
  }

  onClickReason = value => {
    Modal.info({
      title: 'Reject Reason:',
      okText: 'Okay',
      content: (
        <Text>
          {value.rejectReason}
        </Text>
      ),
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { selectedRowKeys } = this.state;
    const { getData: { data, loading, pagination } } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const defaultStatusFilter = [
      { value: null, name: 'All' },
      { value: 'DRAFT', name: 'Draft' },
      { value: 'REQUEST', name: 'Need Approval' },
      { value: 'ACTIVE', name: 'Active' },
      { value: 'INACTIVE', name: 'Inactive' },
      { value: 'REJECT', name: 'Reject' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SoundOutlined /> Job Posting</Breadcrumb.Item>
              <Breadcrumb.Item>Job Posting List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader 
              className="site-page-header" 
              title='Job Posting List' 
              extra={[
                <Link key="1" to="/agency/jobs/create">
                  <Button className="btn-create-primary" type="primary" ghost icon={<PlusOutlined />}>
                    Post a Job
                  </Button>
                </Link>,
              ]}
            />
          </Col>
          {/* Filter */}
          <Col span={24}>
            <HeaderTable 
              fase1={{ key: 'type', value: defaultStatusFilter}}
              onSearch={this.onSearch} 
              onStatus={this.onStatus} 
              onRegistered={this.onRegistered}
              tooltipDate="Filter by Close Application Date"
              tooltipStatus="Filter by Status"
              placeholder="Search by Number, Title, Job Position"
            />
          </Col>
          {/* Table */}
          <Col span={24}>
            <Table 
              size="small" 
              rowKey={(i) => i.id} 
              pagination={false}
              dataSource={data} 
              loading={loading} 
              scroll={{ x: 1200 }}
              rowSelection={rowSelection}  
              onRow={(r) => ({ onClick: () => this.onClickRow(r) })}
              rowClassName={(record) => record.id ? 'row-pointer' : null}
              columns={columns(this.onClickEdit, this.onClickReason, this.handleInactive)} 
            />
          </Col>
          {/* Pagination */}
          <Col span={24}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetData } = this.props;
    return unmountGetData()
  }
}

const mapStateToProps = (state) => ({
  getData: state.jobs.list
})

const mapDispatchToProps = {
  actionGetData   : listJobs,
  actionInactive  : inactiveJob,
  unmountGetData  : unmountListJobs
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyJobs)
