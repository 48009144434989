import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import HeaderDetailJob from '../Header';
import ViewProfile from '../Profile';
import { connect } from 'react-redux';
import { columns } from './columns';
import { Link } from 'react-router-dom';
import { FilterDetailHired, Loading } from '../../../../../components';
import { SoundOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { detailCandidate, unmountDetailCandidate } from '../../../../../redux/actions/candidate/candidateAction';
import { Row, Col, Breadcrumb, PageHeader, Typography, Menu, Modal, message, Card, Table, Pagination, Button } from 'antd';
import { activeApplicant, detailJob, inactiveJob, listRejected, unmountDetailJobs, unmountListRejected } from '../../../../../redux/actions/jobs/jobsAction';
const { Text } = Typography;
const { confirm } = Modal;

class DetailRejectedEmployerJobs extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      url: 'job_employers',
      checked: true,
      visible: false,
      meta: {
        page: 1,
        perpage: 10,
        id: '',
        sort: '',
        field: '',
        search: '',
        startDate: '',
        endDate: '',
        phaseId: -1
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta, url } = this.state; 
    const { actionGetRejected, actionGetDetail, match: { params } } = this.props;
    meta.id = params.id
    actionGetRejected(meta, url)
    actionGetDetail(params.id, url)
  }

  onRangePicker = value => {
    const { meta, url } = this.state;
    const { actionGetRejected } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetRejected(meta, url)
  }

  onStatus = value => {
    const { meta, url } = this.state;
    const { actionGetRejected } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.phaseId = value
    return actionGetRejected(meta, url)
  }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionGetRejected } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value
    return actionGetRejected(meta, url)
  }

  pagination = (page, perpage) => {
    const { actionGetRejected } = this.props;
    const { meta, url } = this.state;
    meta.page = page
    meta.perpage = perpage
    return actionGetRejected(meta, url)
  }

  handleChangeStatus = () => {
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    let self = this
    confirm({
      title: 'Are you sure to set it as inactive?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, self.state.url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, self.state.url)
        })
      },
    });
  }

  handleActiveApplicant = (id) => {
    const { meta, url } = this.state;
    const { actionActiveApplicant, actionGetRejected } = this.props;
    confirm({
      title: 'This application will be back processed to the last stage.',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        return actionActiveApplicant(id, url, () => {
          message.success('Successfully moved')
          return actionGetRejected(meta, url)
        })
      },
    });
  }

  onPriviewProfile = (id) => {
    const { actionGetProfile } = this.props;
    return actionGetProfile(id, () => {
      this.setState({ visible: true })
    })
  }

  onCancel = () => {
    this.setState({ visible: false })
  }

  render() {
    const { checked, visible } = this.state;
    const { getData: { data, loading }, getRejected } = this.props;

    if(loading){
      return <Loading />
    }

    const defaultStatusFilter = [
      { value: -1, name: 'All' },
      { value: '', name: 'Applied' },
      { value: 1, name: 'Qualified' },
      { value: 2, name: 'Interview' },
      { value: 3, name: 'Offered' },
      { value: 4, name: 'Hired' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SoundOutlined /> Job Posting</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/employer/jobs">Job Posting List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Text strong>
                  {data.jobNumber}  
                </Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Job Posting Detail' />
          </Col>
          {/* Card Header */}
          <Col span={24}>
            <HeaderDetailJob 
              data={data} 
              checked={checked} 
              handleChangeStatus={(e) => this.handleChangeStatus(e)} 
            />
          </Col>
          {/* Menu */}
          <Col span={24}>
            <Menu defaultSelectedKeys={['3']} mode="horizontal" style={{ background: 'none'  }}>
              <Menu.Item key="1">
                <Link to={`/employer/jobs/detail/${data.id}/information`}>Information</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={`/employer/jobs/detail/${data.id}/active-applicants/PT00`}>Active Applicants</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to={`/employer/jobs/detail//${data.id}/rejected-applicants`}>Rejected Applicants</Link>
              </Menu.Item>
            </Menu>
          </Col>
          {/* Content */}
          <Col span={24}>
            <Card title="Rejected Applicants">
              <Row gutter={[16, 24]}>
                {/* Filter */}
                <Col span={24}>
                  <FilterDetailHired 
                    onSearch={this.onSearch} 
                    onStatus={this.onStatus} 
                    onRangePicker={this.onRangePicker}
                    fase1={{ key: 'type', value: defaultStatusFilter}}
                    tooltopRangePicker='Filter by Rejected Date'
                  />
                  </Col>
                {/* Table */}
                <Col span={24}>
                  <Table 
                    style={{ marginBottom: 20 }}
                    size="small"
                    rowKey={(i) => i.id} 
                    dataSource={getRejected.data} 
                    loading={getRejected.loading} 
                    columns={columns(this.onPriviewProfile, this.handleActiveApplicant)} 
                    pagination={false}
                    scroll={{ x: 900 }}
                  />
                  {/* Pagination */}
                  <Pagination
                    total={getRejected.pagination.total}
                    onChange={this.pagination}
                    current={getRejected.pagination.page}
                    pageSize={getRejected.pagination.perpage}
                    showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal 
          centered
          width={1200}
          visible={visible}
          title="Applicant Profile"
          onCancel={this.onCancel}
          footer={
            <Button key="back" type="primary" onClick={this.onCancel}>Okay</Button>
          }
        >
          <div style={{ height: 600, overflowY: 'auto', overflowX: 'hidden' }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetRejected, unmountGetProfile } = this.props;
    unmountGetDetail()
    unmountGetRejected()
    unmountGetProfile()
  }
}

const mapStateToProps = (state) => ({
  getData       : state.jobs.detail,
  getRejected   : state.jobs.listRejected,
  getProfile    : state.candidate.detail

})

const mapDispatchToProps = {
  actionInactive        : inactiveJob,
  actionActiveApplicant : activeApplicant,
  actionGetDetail       : detailJob,
  actionGetRejected     : listRejected,
  actionGetProfile      : detailCandidate,
  unmountGetDetail      : unmountDetailJobs,
  unmountGetRejected    : unmountListRejected,
  unmountGetProfile     : unmountDetailCandidate,

}

export default connect(mapStateToProps, mapDispatchToProps)(DetailRejectedEmployerJobs)
