import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Space, Typography, Button, Tooltip } from 'antd';
const { Text } = Typography;

export const columns = (onPriviewProfile, handleActiveApplicant) => {
  return [
    {
      title: 'Name',
      key: 'name',
      fixed: 'left',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip title={record.name ? record.name : "N/A"}>
          <Link to="#" onClick={() => onPriviewProfile(record.candidateId)} style={{ color: '#0076de' }}>
            {record.name ? record.name : "N/A"}
          </Link>
          <br />
          <Text>{record.age ? record.age : "N/A"}, {record.gender ? record.gender === "MALE" ? "Male" : "Female" : "N/A"}</Text>
        </Tooltip>
      )
    },
    {
      title: 'Rejected Date',
      key: 'rejectedDate',
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>
          {record.rejectedDate ? moment(record.rejectedDate).format('DD MMMM YYYY') : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Length of Experience',
      key: 'lengthOfExperience',
      width: 80,
      render: (record) => (
        <Space direction="vertical">
          <Text>{record.lengthOfExperience ? record.lengthOfExperience : "N/A"}</Text>
        </Space>
      )
    },
    {
      title: 'Location',
      key: 'location',
      width: 80,
      render: (record) => (
        <Text>
          {record.location ? record.location : "N/A"}
        </Text>
      )
    },
    {
      title: 'Last Stage',
      key: 'lastStage',
      width: 80,
      render: (record) => (
        <Text>
          {record.lastStage ? record.lastStage : "N/A"}
        </Text>
      )
    },
    {
      title: 'Move to last stage',
      key: 'moveToLastStage',
      align: 'center',
      width: 80,
      render: (record) => (
        <React.Fragment>
          <Button 
            className="button-process" 
            style={{ backgroundColor: 'rgba(0, 118, 222, 0.15)', color: '#0076de', border: 'none' }}
            onClick={() => handleActiveApplicant(record.id)}
          >
            Process
          </Button>
        </React.Fragment>
      )
    },
  ]
}