import React, { Component } from 'react';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';
import { Form, Row, Col, Radio, DatePicker, TimePicker, Input, Button, Avatar, Space, Typography, Divider } from 'antd';
const { Title, Text } = Typography

export default class ContentQualified extends Component {

  disabledDate = (current) => {
    let yesterday = moment().subtract(1, 'days');
    return !current || current.isBefore(yesterday);
  }

  onFinish = (values) => {
    const { onFinish } = this.props;
    return onFinish(values)
  }

  render() {
    const { dataApplicant, openModalProfile, formRef, isResult, isMethod, handleChangeAssessmentResult, handleChangeMethod } = this.props;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={17}>
            <Space size="large">
              <Avatar size={64} src={dataApplicant.profilePic ? dataApplicant.profilePic : null } icon={!dataApplicant.profilePic ? <UserOutlined /> : null} />
              <div>
                <Title level={4}>{dataApplicant.name ? dataApplicant.name : 'N/A'}</Title>
                <Text>
                  {dataApplicant.gender ? dataApplicant.gender === 'MALE' ? 'Male' : 'Female' : 'N/A'}, {`${dataApplicant.age ? `${dataApplicant.age} years` : 'N/A'}`} 
                </Text>
              </div>
            </Space>
          </Col>
          <Col span={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button className="btn-color-blue" onClick={openModalProfile}>
              View Profile
            </Button>
          </Col>
        </Row>
        <Divider />
        <Form onFinish={this.onFinish} layout="vertical" ref={formRef}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="isResult" label="Assessment Result" initialValue={isResult}>
                <Radio.Group onChange={handleChangeAssessmentResult}>
                  <Radio value={true}>Interview</Radio>
                  <Radio value={false}>Reject</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {
              isResult ?
              <React.Fragment>
                <Col span={6}>
                  <Form.Item 
                    label="Interview Date"
                    name="interviewDate"
                    rules={[
                      { required: true, message: 'This is a required field' },
                    ]}
                  >
                    <DatePicker 
                      format="DD/MM/YYYY"
                      style={{ width: '100%' }}
                      disabledDate={this.disabledDate}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item 
                    label="Interview Time"
                    name="interviewTime"
                    rules={[
                      { required: true, message: 'This is a required field' },
                    ]}
                  >
                    <TimePicker style={{ width: '100%' }} format="HH:mm" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item 
                    name="isMethod" 
                    label="Method" 
                    initialValue={true}
                    rules={[
                      { required: true, message: 'This is a required field' },
                    ]}
                  >
                    <Radio.Group onChange={handleChangeMethod}>
                      <Radio value={true}>Direct</Radio>
                      <Radio value={false}>Virtual</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {
                  isMethod ?
                  <Col span={12}>
                    <Form.Item 
                      name="location" 
                      label="Location"
                      rules={[
                        { required: isMethod, message: 'This is a required field' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  :
                  <Col span={12}>
                    <Form.Item 
                      name="virtualLink" 
                      label="Virtual Link"
                      rules={[
                        { required: !isMethod, message: 'This is a required field' },
                        { pattern: /^\S*$/, message: 'No spaces allowed' },
                        { pattern: new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi), message: 'Invalid url' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                }
              </React.Fragment>
              :
              null
            }
            
            <Col span={24}>
              <Form.Item name="notes" label="Notes (optional)" initialValue={null}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button className="btn-save-primary" type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
