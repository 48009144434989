import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Typography, Tooltip, Dropdown, Menu, Tag } from 'antd';
import { EditOutlined, StopOutlined, DashOutlined, CheckCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;

const menu = (record, onClickEdit, handleEnable, handleDisable) => (
  <Menu>
    <Menu.Item icon={<EditOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); onClickEdit(record.id)}}>
      Edit                              
    </Menu.Item>
    {
      record.enabled ?
      <Menu.Item icon={<StopOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleDisable(record.id)}}>
        Set as inactive
      </Menu.Item>
      :
      <Menu.Item icon={<CheckCircleOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleEnable(record.id)}}>
        Set as active
      </Menu.Item>
    }
  </Menu>
);

export const columns = (sortedInfo, onClickEdit, handleEnable, handleDisable) => {
  return [
    {
      title: 'Name',
      key: 'name',
      fixed: 'left',
      sorter: (a, b) => a.name - b.name,
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'name' && sortedInfo.order : null,
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Link className="link" to={`/partners/detail/${record.id}`}>
          <Tooltip title={record.name}>
            {record.name ? record.name : '-'}
          </Tooltip>
        </Link>
      )
    },
    {
      title: 'Created Date',
      key: 'createdDate',
      width: 100,
      render: (record) => (
        <Text>
          {moment(record.createdDate).format('DD MMM YYYY')}
        </Text>
      )
    },
    {
      title: 'Email',
      key: 'email',
      width: 150,
      render: (record) => (
        <Text>
          {record.email ? record.email : '-'}
        </Text>
      )
    },
    {
      title: 'Type',
      key: 'partnersTypeName',
      width: 100,
      render: (record) => (
        <Text>
          {record.partnersTypeName ? record.partnersTypeName : '-'}
        </Text>
      )
    },
    {
      title: 'Contact Person',
      key: 'contactPerson',
      width: 120,
      render: (record) => (
        <Text>
          {record.picName ? record.picName: '-'}
        </Text>
      )
    },
    {
      title: 'Mobile Number',
      key: 'mobileNumber',
      width: 120,
      render: (record) => (
        <Text>
          {`+${record.callingCode}${record.mobilePhone}`}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 80,
      render: (record) => (
        <React.Fragment>
          {
            record.enabled ? 
            <Tag color="green">Active</Tag>
            : 
            <Tag color="orange">Inactive</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 30,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={menu(record, onClickEdit, handleEnable, handleDisable)} placement="bottomCenter" arrow>
          <DashOutlined style={{ fontSize: 20 }} />
        </Dropdown>
      )
    },
  ]
}