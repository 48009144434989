import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import { InfoCircleOutlined, DatabaseOutlined } from '@ant-design/icons';
import { detailProvince, editProvince, unmountDetailProvinces } from '../../../../../redux/actions/master/provinces/provincesAction';
import { masterCountries, unmountMasterCountries } from '../../../../../redux/actions/master/countries/countriesAction';
import { Col, Form, Row, Input, Button, Space, Select, Divider, PageHeader, message, Card, Breadcrumb } from 'antd';

class EditMasterProvince extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
    }
  }

  componentDidMount() {
    const { actionGetCountry, actionGetDetail, match: { params } } = this.props;
    actionGetCountry()
    actionGetDetail(params.id)
  }
  
  onFinish = (values) => {
    const { actionUpdate, history, getData: { data } } = this.props;
    this.setState({ submitLoading: true })
    values.id = data.id
    return actionUpdate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        history.push('/master/provinces')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading } = this.state;
    const { getCountry, getData: { loading, data } } = this.props;

    if(getCountry.loading || loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} layout="vertical">
          <Row gutter={[16, 16]}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/provinces">
                    Master Data List Provinces
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Provinces</Breadcrumb.Item>
                <Breadcrumb.Item>{data.name ? data.name : null}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader className="site-page-header" title="Update Province Data" />
            </Col>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      label="Country" 
                      name="countryId" 
                      initialValue={data.countryId ? data.countryId : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a country"
                        optionFilterProp="children"
                        onChange={this.handleChangeCountry}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getCountry?.data?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Province Name" 
                      name="name" 
                      validateFirst
                      initialValue={data.name ? data.name : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: 'Maximum is 50 characters' },
                        { pattern: new RegExp(/^([^0-9]*)$/), message: 'Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled" 
                      initialValue={data.enabled}
                      tooltip={{ 
                        title: 'Inactive means this province isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Description" 
                      name="description" 
                      initialValue={data.description ? data.description : ''}
                      rules={[
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Divider />
                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Space>
                      <Button className="btn-save-primary" type="default"onClick={() => window.history.back()}>Cancel</Button>
                      <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Update</Button>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetCountry, unmountGetDetail } = this.props;
    unmountGetCountry()
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getCountry  : state.countries.master,
  getData     : state.provinces.detail
})

const mapDispatchToProps = {
  actionGetDetail   : detailProvince,
  actionUpdate      : editProvince,
  actionGetCountry  : masterCountries,
  unmountGetDetail  : unmountDetailProvinces,
  unmountGetCountry : unmountMasterCountries
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMasterProvince)
