import React, { Component } from 'react';
import moment from 'moment';
import EditBiodata from './Edit';
import ImgCrop from 'antd-img-crop';
import svg from '../../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { masterCities, unmountMasterCities } from '../../../../../redux/actions/master/cities/citiesAction';
import { detailCandidate, unmountDetailCandidate, updateAvatar, updateBiodata } from '../../../../../redux/actions/candidate/candidateAction';
import { masterCountries, unmountMasterCountries } from '../../../../../redux/actions/master/countries/countriesAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Menu, Space, Avatar, Typography, Button, Descriptions, Image, Upload, Modal, message } from 'antd';
import { UserOutlined, PhoneOutlined, LikeOutlined, ProjectOutlined, BulbOutlined, IdcardOutlined, FormatPainterOutlined, FolderOutlined, TrophyOutlined, LockOutlined, EditOutlined } from '@ant-design/icons';
const { Text } = Typography;

class CandidateDetailBiodata extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      cityId: null
    }
  }

  componentDidMount() {
    const { actionGetDetail, actionGetCountry, actionGetCity, match: { params } } = this.props;
    actionGetDetail(params.id, (response) => {
      return this.setState({ cityId: response.data.currentCityId })
    })
    actionGetCountry()
    actionGetCity()
  }

  showModal = () => {
    return this.setState({ visible: true })
  }

  onCancel = () => {
    const { actionGetDetail, match: { params } } = this.props;
    return this.setState({ visible: false }, () => {
      actionGetDetail(params.id)
    })
  }

  handleUpload(){
    const { actionUpdateAvatar, actionGetDetail, match: { params } } = this.props;
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".png,.jpg,.jpeg",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const value = {
            'profilePicFile': file,
            'id': Number(params.id)
          }
          return actionUpdateAvatar(value, () => {
            message.success('Successfully uploaded')
            actionGetDetail(params.id)
          })
        }
        return false;
      },
    }
  }

  handleCity = (value, option) => {
    const optionSelected = Number(option.key)
    this.setState({
      cityId: optionSelected
    })
  }

  onFinish = () => {
    const { cityId } = this.state;
    const { actionUpdateBiodata, actionGetDetail, match: { params } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.id = Number(params.id)
      values.currentCityId = cityId
      values.birthdate = values.birthdate ? moment(values.birthdate).format('DD/MM/YYYY') : null
      return actionUpdateBiodata(values, () => {
        return this.setState({ visible: false }, () => {
          message.success('Data updated successfully')
          return actionGetDetail(params.id)
        })
      }, (err) => message.error(err))
    })
  }
  
  render() {
    const { visible } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><UserOutlined /> Candidates</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/candidate">
                  Candidates List
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">CA-210310-Ar792</Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu defaultSelectedKeys={['1']} mode="inline">
                    <Menu.Item key="1" icon={<UserOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/biodata`}>
                        Biodata
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<PhoneOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/contact-information`}>
                        Contact Information
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<LikeOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/job-preferences`}>
                        Job Preferences
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<ProjectOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/work-experience`}>
                        Work Experience
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<BulbOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/education`}>
                        Education
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<IdcardOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/training-and-certification`}>
                        Training and Certification
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="7" icon={<FormatPainterOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/skills`}>
                        Skills
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="8" icon={<FolderOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/portfolios`}>
                        Portfolios
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="9" icon={<TrophyOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/achievements`}>
                        Achievements
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="10" icon={<LockOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/reset-password`}>
                        Reset Password
                      </Link>
                    </Menu.Item>
                  </Menu>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Biodata</Text>} 
              extra={<Button key="1" className="btn-edit-candidate" type="primary" icon={<EditOutlined />} onClick={this.showModal}>Edit</Button>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Descriptions layout="vertical" column={1} size="small">
                    <Descriptions.Item label={<Text type="secondary">Pass Photo</Text>}>
                      <Space direction="vertical">
                        <Image width={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                        <ImgCrop>
                          <Upload {...this.handleUpload()}>
                            <Button type="primary" size="small" ghost style={{ borderRadius: 6 }}>{data.profilePicUrl ? 'Change Photo' : 'Upload Photo'}</Button>
                          </Upload>
                        </ImgCrop>
                      </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">About</Text>}>
                      {data.about ? data.about : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Full Name</Text>}>
                      {data.fullName ? data.fullName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Nationality</Text>}>
                      {data.nationality ? data.nationality : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Gender</Text>}>
                      {data.gender ? data.gender === "FEMALE" ? "Female" : "Male" : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Marital Status</Text>}>
                      {
                        data.marital ?
                        <Text>
                          {data.marital === 'SINGLE' && 'Single'}
                          {data.marital === 'MARRIED' && 'Married'}
                          {data.marital === 'PROTESTAN' && 'Protestan'}
                          {data.marital === 'DIVORCE' && 'Divorce'}
                          {data.marital === 'WIDOWED' && 'Widowed'}
                        </Text>
                        : 
                        'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Place, Date of Birth</Text>}>
                      {data.placeOfBirth ? data.placeOfBirth : 'N/A'}, {data.birthdate ? moment(data.birthdate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Religion</Text>}>
                      {
                        data.religion ?
                        <Text>
                          {data.religion === 'ISLAM' && 'Islam'}
                          {data.religion === 'CATHOLIC' && 'Catholic'}
                          {data.religion === 'PROTESTAN' && 'Protestan'}
                          {data.religion === 'BUDDHA' && 'Buddha'}
                          {data.religion === 'HINDU' && 'Hindu'}
                          {data.religion === 'OTHERS' && 'Others'}
                        </Text>
                        : 
                        'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Current Location</Text>}>
                      {data.currentCityName ? data.currentCityName : 'N/A'}, {data.currentCountryName ? data.currentCountryName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Blood Type</Text>}>
                      {data.bloodType ? data.bloodType : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Height</Text>}>
                      {data.height ? `${data.height} cm` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">ID Card Number (KTP)</Text>}>
                      {data.identificationId ? data.identificationId : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Weight</Text>}>
                      {data.weight ? `${data.weight} kg` : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          centered
          width={1000}
          visible={visible}
          title={<Text strong>Biodata</Text>}
          onCancel={this.onCancel}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={this.onCancel}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onFinish}>Save</Button>
            </React.Fragment>
          ]}
        >
          <div style={{ height: 500, overflowY: 'auto', overflowX: 'hidden' }}>
            <EditBiodata 
              {...this.props} 
              formRef={this.formRef} 
              onFinish={this.onFinish} 
              handleCity={this.handleCity}
            />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetCountry, unmountGetCity } = this.props;
    unmountGetDetail()
    unmountGetCountry()
    unmountGetCity()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
  getCountry          : state.countries.master,
  getCity             : state.cities.master
})

const mapDispatchToProps = {
  actionUpdateAvatar  : updateAvatar,
  actionUpdateBiodata : updateBiodata,
  actionGetDetail     : detailCandidate,
  actionGetCountry    : masterCountries,
  actionGetCity       : masterCities,
  unmountGetDetail    : unmountDetailCandidate,
  unmountGetCountry   : unmountMasterCountries,
  unmountGetCity      : unmountMasterCities
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailBiodata)
