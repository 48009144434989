import React, { Component } from 'react';
import EditJobPreference from './Edit';
import svg from '../../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { positionByIndustryGroup } from '../../../../../redux/actions/master/jobPositions/jobPositionsAction';
import { masterCountries, unmountMasterCountries } from '../../../../../redux/actions/master/countries/countriesAction';
import { detailCandidate, unmountDetailCandidate, updateJobPreference } from '../../../../../redux/actions/candidate/candidateAction';
import { masterJobIndustries, unmountMasterJobIndustries } from '../../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Button, Menu, Space, Avatar, Typography, Descriptions, Modal, message } from 'antd';
import { listJobDestinationGroup, unmountListJobDestinationsGroup } from '../../../../../redux/actions/master/jobDestinations/jobDestinationsAction';
import { UserOutlined, PhoneOutlined, LikeOutlined, ProjectOutlined, BulbOutlined, IdcardOutlined, FormatPainterOutlined, FolderOutlined, TrophyOutlined, LockOutlined, EditOutlined } from '@ant-design/icons';
const { Text } = Typography;

class CandidateDetailJobPreferences extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      isSalary: false,
      salaryFrom: null,
      salaryTo: null,
      dataJobPosition: [],
    }
  }

  componentDidMount() {
    const { 
      actionGetDetail, 
      actionJobIndustry, 
      actionGetJobPosition, 
      actionGetJobDestination, 
      actionGetCountry, 
      match: { params } 
    } = this.props;
    actionGetDetail(params.id, (response) => {
      const idJobIndustry = response.data.listJobIndustry.length !== 0 ? response.data.listJobIndustry.map(item => item.jobIndustryId) : []
      this.setState({ 
        salaryFrom: response.data.salaryFrom,
        salaryTo: response.data.salaryTo
      })
      actionGetJobPosition(idJobIndustry.join(), (res) => {
        this.setState({ dataJobPosition: res })
      })
    })
    actionJobIndustry()
    actionGetJobDestination()
    actionGetCountry()
  }

  showModal = () => {
    return this.setState({ visible: true })
  }

  onCancel = () => {
    const { actionGetDetail, match: { params } } = this.props;
    return this.setState({ visible: false, dataJobPosition: [] }, () => {
      actionGetDetail(params.id)
    })
  }

  handleIndustry = (value) => {
    const { actionGetJobPosition } = this.props;
    const qq = value.join()
    this.formRef.current.setFieldsValue({
      listJobPosition: []
    });
    return actionGetJobPosition(qq, (response) => {
      this.setState({ dataJobPosition: response })
    })
  }

  handleSalaryFrom = (value) => {
    const { salaryTo } = this.state
    this.setState({
      salaryFrom: value,
    })
    if(value){
      if(salaryTo <= value){
        this.setState({ isSalary: true })
      }else{
        this.setState({ isSalary: false })
      }
    }else{
      this.setState({ isSalary: false })
    }
  }

  handleSalaryTo = (value) => {
    const { salaryFrom } = this.state
    this.setState({ salaryTo: value })
    if(salaryFrom){
      if(value <= salaryFrom){
        this.setState({ isSalary: true })
      }else{
        this.setState({ isSalary: false })
      }
    }else{
      this.setState({ isSalary: false })
    }
  }

  onFinish = () => {
    const { actionUpdatePreference, actionGetDetail, match: { params } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.candidateId = params.id
      return actionUpdatePreference(values, () => {
        return this.setState({ visible: false }, () => {
          message.success('Data updated successfully')
          return actionGetDetail(params.id)
        })
      }, (err) => message.error(err))
    })
  }
  
  render() {
    const { visible } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    const dataJobIndustry = data.listJobIndustry ? data.listJobIndustry.map(item => item.jobIndustryName) : []
    const dataJobPosition = data.listJobPosition ? data.listJobPosition.map(item => item.jobPositionName) : []
    const dataJobDestination = data.listJobDestination ? data.listJobDestination.map(item => item.jobDestinationName) : []
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><UserOutlined /> Candidates</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/candidate">
                  Candidates List
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">CA-210310-Ar792</Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu defaultSelectedKeys={['3']} mode="inline">
                    <Menu.Item key="1" icon={<UserOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/biodata`}>
                        Biodata
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<PhoneOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/contact-information`}>
                        Contact Information
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<LikeOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/job-preferences`}>
                        Job Preferences
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<ProjectOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/work-experience`}>
                        Work Experience
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<BulbOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/education`}>
                        Education
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<IdcardOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/training-and-certification`}>
                        Training and Certification
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="7" icon={<FormatPainterOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/skills`}>
                        Skills
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="8" icon={<FolderOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/portfolios`}>
                        Portfolios
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="9" icon={<TrophyOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/achievements`}>
                        Achievements
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="10" icon={<LockOutlined />}>
                      <Link to={`/candidate/detail/${data.id}/reset-password`}>
                        Reset Password
                      </Link>
                    </Menu.Item>
                  </Menu>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Job Preferences</Text>} 
              extra={<Button key="1" className="btn-edit-candidate" type="primary" icon={<EditOutlined />} onClick={this.showModal}>Edit</Button>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Descriptions column={2} layout="vertical" size="small">
                    <Descriptions.Item label={<Text type="secondary">Job Industry</Text>}>
                      {dataJobIndustry.length > 0 ? dataJobIndustry.join(', ') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Salary Expectation</Text>}>
                      {
                        data.salaryTo ?
                        <React.Fragment>
                          <Text>{data.currencyCode ? data.currencyCode : 'N/A'} {data.salaryFrom ? data.salaryFrom.toLocaleString() : 'N/A'} - {data.salaryTo ? data.salaryTo.toLocaleString() : 'N/A'}</Text>
                        </React.Fragment>
                        :
                        <Text>
                          {data.salaryFrom ? `${data.currencyCode ? data.currencyCode : 'N/A'} ${data.salaryFrom ? data.salaryFrom.toLocaleString() : 'N/A'}` : 'N/A'}
                        </Text>
                      }
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions column={1} layout="vertical" size="small">
                    <Descriptions.Item label={<Text type="secondary">Job Position</Text>}>
                      {dataJobPosition.length > 0 ? dataJobPosition.join(', ') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Job Destination</Text>}>
                      {dataJobDestination.length > 0 ? dataJobDestination.join(', ') : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          width={1000}
          destroyOnClose
          visible={visible}
          onCancel={this.onCancel}
          title={<Text strong>Job Preferences</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={this.onCancel}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onFinish}>Save</Button>
            </React.Fragment>
          ]}
        >
          <EditJobPreference 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onFinish={this.onFinish}
            handleSalaryTo={this.handleSalaryTo}
            handleIndustry={this.handleIndustry}
            handleSalaryFrom={this.handleSalaryFrom}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetCountry, unmountGetJobIndustry, unmountGetJobDestination } = this.props;
    unmountGetDetail()
    unmountGetCountry()
    unmountGetJobIndustry()
    unmountGetJobDestination()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
  getCountry          : state.countries.master,
  getJobIndustry      : state.jobIndustries.master,
  getJobPosition      : state.jobPositions.listByIndustry,
  getJobDestination   : state.jobDestinations.group,
})

const mapDispatchToProps = {
  actionUpdatePreference    : updateJobPreference,
  actionGetDetail           : detailCandidate,
  actionGetCountry          : masterCountries,
  actionJobIndustry         : masterJobIndustries,
  actionGetJobPosition      : positionByIndustryGroup,
  actionGetJobDestination   : listJobDestinationGroup,
  unmountGetDetail          : unmountDetailCandidate,
  unmountGetCountry         : unmountMasterCountries,
  unmountGetJobIndustry     : unmountMasterJobIndustries,
  unmountGetJobDestination  : unmountListJobDestinationsGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailJobPreferences)
