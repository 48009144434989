import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UserOutlined, EditOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Row, Col, Space, Avatar, Typography, Button, Divider, Form, Radio, Input, Tooltip, Modal } from 'antd';
const { Title, Text } = Typography;

export default class ContentInterview extends Component {

  showModal = (data) => {
    Modal.info({
      title: 'Reason of request to reschedule:',
      content: (
        <Text>{data.rescheduleReason}</Text>
      ),
    });
  }

  onFinish = (values) => {
    const { onFinish } = this.props;
    return onFinish(values)
  }

  render() {
    const { 
      dataApplicant, 
      isResult, 
      openModalProfile, 
      formRef,
      openModal,
      handleChangeAssessmentResult,
      onCancelInterview
    } = this.props;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={17}>
            <Space size="large">
              <Avatar size={64} src={dataApplicant.profilePic ? dataApplicant.profilePic : null } icon={!dataApplicant.profilePic ? <UserOutlined /> : null} />
              <div>
                <Title level={4}>{dataApplicant.name}</Title>
                {
                  dataApplicant.status === "Interview today" &&
                  <Text style={{ color: '#37ac71' }}>Interview today</Text>
                }
                {
                  dataApplicant.status === "Request to schedule" &&
                  <Space>
                    <Text style={{ color: '#f23c3c' }}>Request to reschedule</Text>
                    <Button onClick={() => this.showModal(dataApplicant)} type="primary" ghost size="small" style={{ border: '1px solid #0076de', color: '#0076de' }}>Reason</Button>
                  </Space>
                }
                {
                  dataApplicant.status === "Awaiting confirmation" &&
                  <Text style={{ color: '#979797' }}>Awaiting confirmation</Text>
                }
                {
                  dataApplicant.status === "Missed interview" &&
                  <Text style={{ color: '#ff8a1c' }}>Missed interview</Text>
                }
                {
                  dataApplicant.status === "Confirmed" &&
                  <Text style={{ color: '#14bfbd' }}>Confirmed</Text>
                }
                {
                  dataApplicant.status === "Awaiting reschedule confirmation" &&
                  <Text style={{ color: '#979797' }}>Awaiting reschedule confirmation</Text>
                }
              </div>
            </Space>
          </Col>
          <Col span={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button className="btn-color-blue" onClick={openModalProfile}>
              View Profile
            </Button>
          </Col>
        </Row>
        <Divider />
        <Form onFinish={this.onFinish} layout="vertical" ref={formRef}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Notes of Qualified Stage:">
                <Text strong>{dataApplicant.notes ? dataApplicant.notes : 'N/A'}</Text>
              </Form.Item>
              <Divider dashed />
            </Col>

            <Col span={12}>
              <Form.Item label="Interview Date and Time">
                <Space size="small">
                  <Text>{dataApplicant.interviewDate ? dataApplicant.interviewDate : 'N/A'}, {dataApplicant.interviewTime ? dataApplicant.interviewTime : 'N/A'}</Text>
                  <Button icon={<EditOutlined />} size="small" onClick={openModal}>
                    Edit Schedule
                  </Button>
                </Space>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Method">
                <Text>{dataApplicant.method === 'Direct' && 'Direct'}</Text>
                <Text>{dataApplicant.method === 'Virtual Link' && 'Virtual'}</Text>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="isResult" label="Assessment Result" initialValue={isResult}>
                <Radio.Group onChange={handleChangeAssessmentResult}>
                  <Radio value={true}>To be Offered</Radio>
                  <Radio value={false}>Reject</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              {
                dataApplicant.method === "Direct" ?
                <Form.Item label="Location">
                  <Text>{dataApplicant.location}</Text>
                </Form.Item>
                :
                <Form.Item label="Virtual Link">
                  <Text>{dataApplicant.virtualLink}</Text>
                </Form.Item>
              }
            </Col>

            <Col span={24}>
              <Form.Item name="notes" label="Notes (optional)" initialValue={null}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button className="btn-save-primary" type="primary" htmlType="submit">
                Submit
              </Button>
              <Space>
              <Link to="#" onClick={() => onCancelInterview(dataApplicant.jobInterviewId)} style={{ color: '#ea5342' }}>Cancel Interviews</Link> 
              <Tooltip title="By cancelling this scheduled interview, the application  will be moved to rejected. ">
                <InfoCircleTwoTone />
              </Tooltip>
              </Space>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
