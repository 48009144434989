import React from 'react';
import { Typography } from 'antd';
const { Text } = Typography;

export const columns = () => {
  return [
    {
      title: 'Applicant Name',
      key: 'applicantName',
      width: 150,
      render: (record) => (
        <Text>
          {record.applicantName ? record.applicantName : 'N/A'}
        </Text>
      )
    },
    {
      title: 'ID Card Number (KTP)',
      key: 'identificationId',
      width: 150,
      render: (record) => (
        <Text>
          {record.identificationId ? record.identificationId : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Date of Apply',
      key: 'dateOfApply',
      width: 150,
      render: (record) => (
        <Text>
          {record.dateOfApply ? record.dateOfApply : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Time Of Apply',
      key: 'timeOfApply',
      width: 100,
      render: (record) => (
        <Text>
          {record.timeOfApply ? record.timeOfApply : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Posting Title',
      key: 'postingTitle',
      width: 150,
      render: (record) => (
        <Text>
          {record.postingTitle ? record.postingTitle : 'N/A'}
        </Text>
      )
    }
  ]
}