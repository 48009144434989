import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountSliderPhoto = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_APPLICANT_SUMMARY'})
}

export const unmountAbout = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_ABOUT'})
}

export const unmountLocation = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_LOCATION'})
}

export const sliderPhoto = (successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_SLIDER_PHOTO' })
  API.GET(`/company_profile/list_image`).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_SLIDER_PHOTO_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_SLIDER_PHOTO_FAILED' }), 
      ))
    )
  })
}

export const getAbout = (successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_ABOUT' })
  API.GET(`/employers/getAbout`).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_ABOUT_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_ABOUT_FAILED' }), 
      ))
    )
  })
}

export const getLocation = (successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_LOCATION' })
  API.GET(`/employers/getLocation`).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_LOCATION_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_LOCATION_FAILED' }), 
      ))
    )
  })
}

export const updateSliderPhoto = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY('/company_profile/update_headers', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateAbout = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/employers/update_about', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateLocation = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/employers/update_location', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}
