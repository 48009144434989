import React, { Component } from 'react';
import DashboardAdmin from './Admin';
import DashboardAgency from './Agency';
import DashboardEmployer from './Employer';
import { connect } from 'react-redux';
import { applicantSummary, jobOpeningStatus, jobOverview, pipelineHiring, unmountApplicantSummary, unmountJobOpeningStatus, unmountJobOverview, unmountPipelineHiring } from '../../redux/actions/dashboard/dashboardAction';

class Dashboard extends Component {
  render() {
    const { getAuth } = this.props;
    return (
      <React.Fragment>
        {
          getAuth.role === "ROLE_ADMIN_JOBSHUB" &&
          <DashboardAdmin />
        }
        {
          getAuth.role === "ROLE_AGENCY" &&
          <DashboardAgency {...this.props} />
        }
        {
          getAuth.role === "ROLE_EMPLOYERS" &&
          <DashboardEmployer {...this.props} />
        }
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetPipelineHiring, unmountGetApplicantSummary, unmountGetJobOverview, unmountGetJobOpeningStatus } = this.props;
    unmountGetPipelineHiring()
    unmountGetApplicantSummary()
    unmountGetJobOverview()
    unmountGetJobOpeningStatus()
  }
}

const mapStateToProps = (state) => ({
  getAuth               : state.authReducer,
  getApplicantSummary   : state.dashboard.applicantSummary,
  getPipelineHiring     : state.dashboard.pipelineHiring,
  getJobOverview        : state.dashboard.jobOverview,
  getJobOpeningStatus   : state.dashboard.jobOpeningStatus
})

const mapDispatchToProps = {
  actionGetApplicantSummary   : applicantSummary,
  actionGetPipelineHiring     : pipelineHiring,
  actionGetJobOverview        : jobOverview,
  actionGetJobOpeningStatus   : jobOpeningStatus,
  unmountGetPipelineHiring    : unmountPipelineHiring,
  unmountGetApplicantSummary  : unmountApplicantSummary,
  unmountGetJobOverview       : unmountJobOverview,
  unmountGetJobOpeningStatus  : unmountJobOpeningStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
