import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import { InfoCircleOutlined, DatabaseOutlined } from '@ant-design/icons';
import { createJobPosition } from '../../../../../redux/actions/master/jobPositions/jobPositionsAction';
import { Col, Form, Row, Input, Button, Space, Select, Divider, PageHeader, message, Card, Breadcrumb } from 'antd';
import { masterJobIndustries, unmountMasterJobIndustries } from '../../../../../redux/actions/master/jobIndustries/jobIndustriesAction';

class CreateMasterJobPositions extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false
    }
  }

  componentDidMount() {
    const { actionGetJobIndustries } = this.props;
    return actionGetJobIndustries()
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    this.setState({ submitLoading: true })
    return actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/master/job-positions')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading } = this.state;
    const { getJobIndustries: { data, loading } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} layout="vertical">
          <Row gutter={[16, 16]}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/job-positions">
                    Master Data List Job Positions
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Job Positions</Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader className="site-page-header" title="Create a Job Position" />
            </Col>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      label="Job Industry" 
                      name="jobIndustryId"
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select an Job Industry"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          data?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.title}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Job Position Name" 
                      name="title" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: '50 characters only' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Number and special character are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Will it be displayed in the candidate's side?" 
                      name="isDisplay" 
                      initialValue={false}
                      tooltip={{ 
                        title: 'If you choose yes, this job postions will be displayed in the Job Preferences option', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Yes</Select.Option>
                        <Select.Option value={false}>No</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled" 
                      initialValue={true}
                      tooltip={{ 
                        title: 'Inactive means this job position isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option key="1" value={true}>Active</Select.Option>
                        <Select.Option key="2" value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Description" 
                      name="description"
                      initialValue=""
                      rules={[
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Divider />
                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Space>
                      <Button className="btn-save-primary" type="default"onClick={() => window.history.back()}>Cancel</Button>
                      <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                    </Space>
                  </Col>

                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetJobIndustries } = this.props;
    return unmountGetJobIndustries()
  }
}

const mapStateToProps = (state) => ({
  getJobIndustries: state.jobIndustries.master
})

const mapDispatchToProps = {
  actionCreate            : createJobPosition,
  actionGetJobIndustries  : masterJobIndustries,
  unmountGetJobIndustries : unmountMasterJobIndustries
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMasterJobPositions)
