import React, { Component } from 'react';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';
import { Loading } from '../../../../../components';
import { Row, Col, Avatar, Typography, Space, Descriptions, Divider, Image } from 'antd';
const { Text } = Typography;

export default class ViewProfile extends Component {
  render() {
    const { getProfile: { data, loading } } = this.props;
    if(loading){
      <Loading />
    }
    const dataJobIndustry = data.listJobIndustry ? data.listJobIndustry.map(item => item.jobIndustryName) : []
    const dataJobDestination = data.listJobDestination ? data.listJobDestination.map(item => item.jobDestinationName) : []
    const dataJobPosition = data.listJobPosition ? data.listJobPosition.map(item => item.jobPositionName) : []
    return (
      <React.Fragment>
        {/* Header */}
        <Row gutter={16}>
          <Col span={3}>
            <Avatar size={100} icon={<UserOutlined />} src={data.profilePicUrl ? <Image src={data.profilePicUrl} /> : null } />
          </Col>
          <Col span={8}>
            <Space direction="vertical" size="small">
              <Text>{data.fullName ? data.fullName : 'N/A'}</Text>
              <Text>{data.email ? data.email : 'N/A'}</Text>
              <Text>{`+${data.callingCode ? data.callingCode : 'N/A'}${data.mobilePhone ? data.mobilePhone : 'N/A'}`}</Text>
              <Text>Current location: {data.currentCityName ? data.currentCityName : 'N/A'}, {data.currentCountryName ? data.currentCountryName : 'N/A'}</Text>
            </Space>
          </Col>
          <Col span={13}>
            <Descriptions layout="vertical" size="small">
              <Descriptions.Item label="About">
                {data.about ? data.about : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Divider />

         {/* Job Preference */}
         <Row gutter={16}>
          <Col span={24}>
            <Descriptions layout="vertical" size="small" column={3}>
              <Descriptions.Item label="Job Industries Preference">
                {dataJobIndustry.length > 0 ? dataJobIndustry.join(', ') : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Job Destinations Preference">
                {dataJobDestination.length > 0 ? dataJobDestination.join(', ') : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions layout="vertical" size="small" column={3}>
              <Descriptions.Item label="Job Positions Preference">
                {dataJobPosition.length > 0 ? dataJobPosition.join(', ') : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Salary Expectation">
                {
                  data.salaryTo ?
                  <React.Fragment>
                    <Text>{data.currencyCode ? data.currencyCode : 'N/A'} {data.salaryFrom ? data.salaryFrom.toLocaleString() : 'N/A'} - {data.salaryTo ? data.salaryTo.toLocaleString() : 'N/A'}</Text>
                  </React.Fragment>
                  :
                  <Text>
                    {data.salaryFrom ? `${data.currencyCode ? data.currencyCode : 'N/A'} ${data.salaryFrom ? data.salaryFrom.toLocaleString() : 'N/A'}` : 'N/A'}
                  </Text>
                }
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Divider />

        {/* Personal Information */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Personal Information" layout="vertical" size="small" column={3}>
              <Descriptions.Item label="Date of Birth">
                {data.birthdate ? moment(data.birthdate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Height (cm)">
                {data.height ? data.height : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="City (ID Card)">
                {data.cityName ? data.cityName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Nationality">
                {data.nationality ? data.nationality : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Weight (kg)">
                {data.weight ? data.weight : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Sub-district (ID card)">
                {data.subDistrictName ? data.subDistrictName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Place of Birth">
                {data.placeOfBirth ? data.placeOfBirth : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Religion">
                {
                  data.religion ?
                  <Text>
                    {data.religion === 'ISLAM' && 'Islam'}
                    {data.religion === 'CATHOLIC' && 'Catholic'}
                    {data.religion === 'PROTESTAN' && 'Protestan'}
                    {data.religion === 'BUDDHA' && 'Buddha'}
                    {data.religion === 'HINDU' && 'Hindu'}
                    {data.religion === 'OTHERS' && 'Others'}
                  </Text>
                  : 
                  'N/A'
                }
              </Descriptions.Item>
              <Descriptions.Item label="Village (ID card)">
                {data.villageName ? data.villageName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Marital Status">
                {
                  data.marital ?
                  <Text>
                    {data.marital === 'SINGLE' && 'Single'}
                    {data.marital === 'MARRIED' && 'Married'}
                    {data.marital === 'PROTESTAN' && 'Protestan'}
                    {data.marital === 'DIVORCE' && 'Divorce'}
                    {data.marital === 'WIDOWED' && 'Widowed'}
                  </Text>
                  : 
                  'N/A'
                }
              </Descriptions.Item>
              <Descriptions.Item label="ID Card Number (KTP)">
                {data.identificationId ? data.identificationId : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Postal Code (ID card)">
                {data.zipCode ? data.zipCode : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Gender">
                {
                  data.gender ?
                  <Text>
                    {data.gender === 'MALE' && 'Male'}
                    {data.gender === 'FEMALE' && 'Female'}
                  </Text>
                  : 
                  'N/A'
                }
              </Descriptions.Item>
              <Descriptions.Item label="Country (ID Card)">
                {data.countryName ? data.countryName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Street Address">
                {data.address ? data.address : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Blood Type">
                {data.bloodType ? data.bloodType : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Province (ID Card)">
                {data.provinceName ? data.provinceName : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Divider />

        {/* Work Experiences */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Work Experiences" />
            {
              data.listWorkExperience.length > 0 ?
              <React.Fragment>
                {
                  data.listWorkExperience.map((item, i) => (
                    <React.Fragment key={i}>
                      <div style={{ marginBottom: 11 }}>
                        <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                          {moment(item.startDate, 'YYYY-MM').format('MMMM YYYY')} - {item.endDate ? moment(item.endDate, 'YYYY-MM').format('MMMM YYYY') : 'Present'}
                        </Text>
                      </div>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Title">
                          {item.title}
                        </Descriptions.Item>
                        <Descriptions.Item label="Employment Type">
                          {
                            item.employmentType ?
                            <Text>
                              {item.employmentType === "FULL_TIME" && 'Full Time'}
                              {item.employmentType === "PART_TIME" && 'Part Time'}
                              {item.employmentType === "FREELANCE" && 'Freelance'}
                              {item.employmentType === "INTERNSHIP" && 'Internship'}
                            </Text>
                            :
                            'N/A'
                          }
                        </Descriptions.Item>
                        <Descriptions.Item label="Company">
                          {item.company ? item.company : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Location">
                          {item.provinceName ? item.provinceName : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Job Description">
                          {item.jobDescription ? item.jobDescription : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />
                    </React.Fragment>
                  ))
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
          </Col>
        </Row>

        {/* Education */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Educations" />
            {
              data.listEducation.length > 0 ?
              <React.Fragment>
                {
                  data.listEducation.map((item, i) => (
                    <React.Fragment key={i}>
                      <div style={{ marginBottom: 11 }}>
                        <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                          {item.startYear} - {item.endYear ? item.endYear : 'Present'}
                        </Text>
                      </div>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="School / University">
                          {item.name ? item.name : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Field of Study">
                          {item.fieldOfStudy ? item.fieldOfStudy : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Education Level">
                          {item.educationLevelName ? item.educationLevelName : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Score/Grade/GPA">
                          {item.gpa ? item.gpa : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Activity and Organization Experiences">
                          {item.experience ? item.experience : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />
                    </React.Fragment>
                  ))
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
          </Col>
        </Row>

        {/* Training and Certifications */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Training and Certifications" />
            {
              data.listTraining.length > 0 ?
              <React.Fragment>
                {
                  data.listTraining.map((item, i) => (
                    <React.Fragment key={i}>
                      <div style={{ marginBottom: 11 }}>
                        <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                          {`${item.issueDate ? moment(item.issueDate, 'YYYY-MM').format('MMMM YYYY') : 'N/A'}, ${item.expDate ? moment(item.expDate, 'YYYY-MM').format('MMMM YYYY') : 'certificate will not expire'}`}
                        </Text>
                      </div>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Name">
                          {item.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Credential ID">
                          {item.credentialId ? item.credentialId : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Issuing Organization">
                          {item.organization ? item.organization : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Credential URL">
                          {
                            item.credentialUrl ? 
                            <a href={`${item.credentialUrl}`} target="_blank" rel='noreferrer'>
                              {item.credentialUrl}
                            </a> 
                            : 
                            'N/A'
                          }
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />
                    </React.Fragment>
                  ))
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
          </Col>
        </Row>

        {/* Skills */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Skills" />
            {
              data.listSkill.length > 0 ?
              <React.Fragment>
                <Descriptions layout="vertical" size="small" column={3}>
                  {
                    data.listSkill.map((item, i) => (
                    <Descriptions.Item key={i}>
                      <Space direction="vertical" size="small">
                        <Text>{item.name}</Text>
                        {
                          item.skillLevel ?
                          <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                            {item.skillLevel === 'BASIC' && 'Basic'}
                            {item.skillLevel === 'INTERMEDIATE' && 'Intermediate'}
                            {item.skillLevel === 'ADVANCE' && 'Advance'}
                            {item.skillLevel === 'EXPERT' && 'Expert'}
                          </Text>
                          :
                          'N/A'
                        }
                      </Space>
                    </Descriptions.Item>
                    ))
                  }
                </Descriptions>
                <Divider />
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
          </Col>
        </Row>

        {/* Job Preference */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Portfolios" layout="vertical" size="small" column={3}>
            {
              data.listPortfolio.length > 0 ?
              <React.Fragment>
                {
                  data.listPortfolio.map((item, i) => (
                  <Descriptions.Item key={i}>
                    <a href={`${item.url}`} target="_blank" rel='noreferrer'>
                      {item.url}
                    </a> 
                  </Descriptions.Item>
                  ))
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
            </Descriptions>
          </Col>
        </Row>

        <Divider />

        {/* Achievements */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Achievements" />
            {
              data.listAchievement.length > 0 ?
              <React.Fragment>
                {
                  data.listAchievement.map((item, i) => (
                    <React.Fragment key={i}>
                      <div style={{ marginBottom: 11 }}>
                        <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                          {item.year ? item.year : 'N/A'}
                        </Text>
                      </div>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Name">
                          {item.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Issuing Organization">
                          {item.organization}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />
                    </React.Fragment>
                  ))
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
